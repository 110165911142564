import {
  PRODUCTION_FULL_OPERATIONAL_TESTING,
  PRODUCTION_DEFICIENCIES_ADDRESSED,
  PRODUCTION_COMPONENTS_INTEGRATED,
  PRODUCTION_ENGINEERING_SUPPORT,
  PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED,
  PROTOTYPE_COMPLETE_INTEGRATED,
  PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED,
  PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
  PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED,
  PROTOTYPE_DESIGNS_CONTROLLED_LAB,
  PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED,
  EXPERIMENTAL_STUDIES,
  CONFIRMED_HYPOTHESES,
  TESTING_AND_VERIFICATION,
  MATERIALS_AND_PROCESSES_IDENTIFIED,
  PRODUCTION_FULL_RATE,
  PRODUCTION_EFFICIENCY_STANDARDS,
  PRODUCTION_FUNDING,
  PRODUCTION_LOW_RATE,
  MANUFACTURING_REQUIREMENTS,
  PROTOTYPE_MANUFACTURING_CAPABILITIES,
  PROTOTYPE_FACILITIES_SUFFICIENT,
  PROTOTYPE_PROCESSING_DEMONSTRATED,
  PROTOTYPE_RISKS_PLAN,
  PROTOTYPE_COSTS,
  PROTOTYPE_SPECIAL_FACILITIES,
} from './questions';

export const SCORE_10 = 10;
export const SCORE_9 = 9;
export const SCORE_8 = 8;
export const SCORE_7 = 7;
export const SCORE_6 = 6;
export const SCORE_5 = 5;
export const SCORE_4 = 4;
export const SCORE_3 = 3;
export const SCORE_2 = 2;
export const SCORE_1 = 1;
export const SCORE_0 = 0;

// There are 3 cases to deal with summarySteps:
// 1. If question hasn't been answered or the answer is INPUT_NO -> use INPUT_NO summaryStep
// 2. If revealSumStepIfNotSelected exists and INPUT_NA was not selected -> use all summarySteps if answer was not selected
// 3. If PROTOTYPE_OR_PRODUCTION hasn't been answered -> use INPUT_CONCEPT summaryStep
// TODO: We can find a better way to handle these cases
interface NextStepsInfoProps {
  subtitle: string;
  definition: string;
  steps: string[];
}

export interface NextStepsProps {
  [key: number]: NextStepsInfoProps;
}

export const TRL_NEXT_STEPS: NextStepsProps = {
  [SCORE_9]: {
    subtitle: 'Actual system proven through successful mission operations.',
    definition:
      'Actual application of the technology in its final form being used in an operational context by people who were not involved in the development (e.g., customers, users).',
    steps: [],
  },
  [SCORE_8]: {
    subtitle: 'Actual system completed and qualified through test and demonstration.',
    definition:
      'Technology has been proven to work in its final form and under expected operational conditions, such as those encountered in operational test and evaluation.',
    steps: [PRODUCTION_COMPONENTS_INTEGRATED, PRODUCTION_ENGINEERING_SUPPORT],
  },
  [SCORE_7]: {
    subtitle: 'System prototype demonstration in an operational environment.',
    definition: 'Prototype near or at planned operational system.',
    steps: [PRODUCTION_FULL_OPERATIONAL_TESTING, PRODUCTION_DEFICIENCIES_ADDRESSED],
  },
  [SCORE_6]: {
    subtitle: 'Prototype demonstration in a simulated operational environment.',
    definition:
      "Representative model or prototype system, is tested in a simulated operational environment. Represents a major step up in a technology's demonstrated readiness.",
    steps: [
      PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
      PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED,
      PROTOTYPE_COMPLETE_INTEGRATED,
    ],
  },
  [SCORE_5]: {
    subtitle: 'Component validation in relevant environment.',
    definition:
      'Fidelity of technology increases significantly. The basic technological components are integrated so they can be tested in a simulated environment.',
    steps: [PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING, PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED],
  },
  [SCORE_4]: {
    subtitle: 'Component validation in laboratory environment.',
    definition:
      'Basic technological components are integrated to establish that they will work together. This is low fidelity compared with the eventual system.',
    steps: [PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB, PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED],
  },
  [SCORE_3]: {
    subtitle: 'Analytical and experimental critical function and/or characteristic proof of concept.',
    definition:
      'Active research and development is initiated. This includes analytical studies and laboratory studies to physically validate the analytical predictions of separate elements of the technology. Examples include components that are not yet integrated or representative.',
    steps: [PROTOTYPE_DESIGNS_CONTROLLED_LAB, PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED],
  },
  [SCORE_2]: {
    subtitle: 'Technology concept and/or application formulated.',
    definition:
      'Invention begins. Once basic principles are observed, practical applications can be invented. Applications are speculative, and there may be no proof or detailed analysis to support the assumptions. Examples are limited to analytic studies.',
    steps: [EXPERIMENTAL_STUDIES, CONFIRMED_HYPOTHESES],
  },
  [SCORE_1]: {
    subtitle: 'Basic principles observed and reported.',
    definition:
      "Lowest level of technology readiness. Scientific research begins to be translated into applied research and development. Examples include paper studies of a technology's basic properties.",
    steps: [TESTING_AND_VERIFICATION],
  },
};

export const MRL_NEXT_STEPS: NextStepsProps = {
  [SCORE_10]: {
    subtitle: 'Full Rate Production demonstrated and lean production practices in place.',
    definition:
      'System is in full rate production and processes have been refined to satisfy all engineering, performance, quality and reliability requirements.',
    steps: [],
  },
  [SCORE_9]: {
    subtitle: 'Low rate production demonstrated; Capability in place to begin Full Rate Production (FRP).',
    definition: 'Manufacturing and quality processes and procedures are developed to enable full rate production.',
    steps: [PRODUCTION_FUNDING],
  },
  [SCORE_8]: {
    subtitle: 'Pilot line capability demonstrated; ready to begin Low Rate Initial Production (LRIP).',
    definition:
      'Manufacturing and quality processes and procedures have been proven on a pilot line and are ready for low-rate production.',
    steps: [PRODUCTION_FULL_RATE, PRODUCTION_EFFICIENCY_STANDARDS],
  },
  [SCORE_7]: {
    subtitle: 'Capability to produce systems, subsystems, or components in a production representative environment.',
    definition:
      'Manufacturing processes and procedures have been demonstrated in a production representative environment and materials have been approved for low-rate production.',
    steps: [PRODUCTION_LOW_RATE],
  },
  [SCORE_6]: {
    subtitle: 'Capability to produce a prototype system or subsystem in a production relevant environment.',
    definition:
      'Manufacturing processing, materials, tooling and test equipment have been demonstrated in a relevant environment.',
    steps: [PROTOTYPE_MANUFACTURING_CAPABILITIES, PROTOTYPE_FACILITIES_SUFFICIENT],
  },
  [SCORE_5]: {
    subtitle: 'Capability to produce prototype components in a production relevant environment.',
    definition:
      'A detailed plan has been established for developing a manufacturing process that accounts for potential risks and includes plans for mitigation.',
    steps: [PROTOTYPE_PROCESSING_DEMONSTRATED],
  },
  [SCORE_4]: {
    subtitle: 'Capability to produce the technology in a laboratory environment.',
    definition:
      'Key design performance parameters, relative to the final operating environment, have been identified as well as any special tooling, facilities, material handling and skills required.',
    steps: [PROTOTYPE_RISKS_PLAN],
  },
  [SCORE_3]: {
    subtitle: 'Manufacturing proof of concept developed.',
    definition:
      'Validation of the manufacturing concepts through analytical or laboratory experiments begins. Experimental hardware models have been developed in a laboratory environment that may possess limited functionality.',
    steps: [PROTOTYPE_COSTS, PROTOTYPE_SPECIAL_FACILITIES],
  },
  [SCORE_2]: {
    subtitle: 'Manufacturing concepts identified.',
    definition:
      'Identification, paper studies and analysis of material and process approaches. An understanding of manufacturing feasibility and risk is emerging.',
    steps: [MATERIALS_AND_PROCESSES_IDENTIFIED],
  },
  [SCORE_1]: {
    subtitle: 'Basic manufacturing implications identified.',
    definition:
      'Lowest level of manufacturing readiness. The focus is to address manufacturing shortfalls and opportunities needed to achieve program objectives. Basic research begins in the form of studies.',
    steps: [MANUFACTURING_REQUIREMENTS],
  },
  [SCORE_0]: {
    subtitle: '',
    definition: '',
    steps: [],
  },
};

export const HRL_NEXT_STEPS: NextStepsProps = {
  [SCORE_9]: {
    subtitle: 'System successfully used in operations across the operational envelope with systematic monitoring.',
    definition: 'System successfully used in operations across the operational envelope with systematic monitoring.',
    steps: [],
  },
  [SCORE_8]: {
    subtitle:
      'Human systems design fully tested, verified, and approved in mission operations, using completed system.',
    definition:
      'Human systems design fully tested, verified, and approved in mission operations, using completed system.',
    steps: [],
  },
  [SCORE_7]: {
    subtitle:
      'Human systems design fully tested and verified in operational environment with system hardware and software and representative users.',
    definition:
      'Human systems design fully tested and verified in operational environment with system hardware and software and representative users.',
    steps: [],
  },
  [SCORE_6]: {
    subtitle:
      'Human systems design fully matured and demonstrated in a relevant high-fidelity environment or actual environment.',
    definition:
      'Human systems design fully matured and demonstrated in a relevant high-fidelity environment or actual environment.',
    steps: [],
  },
  [SCORE_5]: {
    subtitle:
      'Human-centered evalutaion of prototypes in mission-relevant part-task simulations completed to inform design.',
    definition:
      'Human-centered evalutaion of prototypes in mission-relevant part-task simulations completed to inform design.',
    steps: [],
  },
  [SCORE_4]: {
    subtitle: 'Modeling, part-tasking, and trade studies of human systems design concepts and applications completed.',
    definition:
      'Modeling, part-tasking, and trade studies of human systems design concepts and applications completed.',
    steps: [],
  },
  [SCORE_3]: {
    subtitle: 'Human-centered requirements to support human performance and human-technology interactions established.',
    definition:
      'Human-centered requirements to support human performance and human-technology interactions established.',
    steps: [],
  },
  [SCORE_2]: {
    subtitle: 'Human-centered concepts, applications, and guidelines defined.',
    definition: 'Human-centered concepts, applications, and guidelines defined.',
    steps: [],
  },
  [SCORE_1]: {
    subtitle: 'Basic principles for human characteristics, performance, and behavior observed and reported.',
    definition: 'Basic principles for human characteristics, performance, and behavior observed and reported.',
    steps: [],
  },
};

interface MrlStepHelperProps {
  [key: number]: string;
}

export const IDENTIFY_MRL_STEP: MrlStepHelperProps = {
  [SCORE_1]: MATERIALS_AND_PROCESSES_IDENTIFIED,
  [SCORE_2]: MATERIALS_AND_PROCESSES_IDENTIFIED,
  [SCORE_3]: MATERIALS_AND_PROCESSES_IDENTIFIED,
  [SCORE_4]: PROTOTYPE_COSTS,
  [SCORE_5]: PROTOTYPE_RISKS_PLAN,
  [SCORE_6]: PROTOTYPE_PROCESSING_DEMONSTRATED,
  [SCORE_7]: PROTOTYPE_MANUFACTURING_CAPABILITIES,
  [SCORE_8]: PRODUCTION_LOW_RATE,
  [SCORE_9]: PRODUCTION_FULL_RATE,
};
