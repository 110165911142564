import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../store/reducers';
import NextStepsList from '../../components/nextStepsList/NextStepsList';
import EnvBox from '../../components/envBox/EnvBox';
import { SectionsProps } from '../results/Results';

const SuggestedNextStepsSection = ({ environmentInputs }: SectionsProps): ReactElement => {
  const { trlScore, mrlScore, trlNextSteps, mrlNextSteps } = useSelector((state: Store) => state.TrlMrlCalculator);

  return (
    <section className="SuggestedNextStepsSec" data-testid="suggested-next-steps">
      <NextStepsList type="TRL" score={trlScore} nextSteps={trlNextSteps} />
      <NextStepsList type="MRL" score={mrlScore} nextSteps={mrlNextSteps} />
      <section className="SuggestedNextStepsSec__envBoxes">
        <EnvBox environmentInputs={environmentInputs} />
      </section>
    </section>
  );
};

export default SuggestedNextStepsSection;
