import React, { ReactElement } from 'react';
// Style
import './Answers.scss';

interface AnswersListProps {
  type: string;
  questionsAnswered: object;
  questionsReference: any;
}

const AnswersList = ({ type, questionsAnswered, questionsReference }: AnswersListProps): ReactElement => {
  const questionsAnsweredArray = Object.entries(questionsAnswered);
  const PROTOTYPE = '#F8D2B2';
  const PRODUCTION = '#8EC4D5';
  const R_AND_D = '#EB8E8E';

  const getQuestionColor = (key: string): string => {
    const keySeparated = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    const keyFirstWord = keySeparated.replace(/ .*/, '');

    if (keyFirstWord === 'prototype') return PROTOTYPE;
    if (keyFirstWord === 'production') return PRODUCTION;

    return R_AND_D;
  };

  return (
    <section className="AnswersList">
      <h4 className={`AnswersList__title AnswersList__title-${type}`}>{type} Questions</h4>
      {questionsAnsweredArray.map(([key, value], index) => {
        const answers = Object.entries(value);
        const firstWord = getQuestionColor(key);
        return (
          <div key={key} className="AnswersList__section">
            <section className="AnswersList__section-circle">
              <svg viewBox="0 0 40 40" height="40" width="40">
                <circle cx="20" cy="20" r="15" fill={firstWord} />
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  fontSize="12px"
                  fontFamily="Arial"
                  fontWeight="800"
                  fill="black"
                  dy=".3em"
                >
                  Q{index + 1}
                </text>
              </svg>
            </section>
            <section className="AnswersList__section-content">
              <p className="AnswersList__section-content-question">{questionsReference[key]?.content}</p>
              {answers.map(([AnswerKey]) => (
                <p className="AnswersList__section-content-answer" key={AnswerKey}>
                  {`- ${questionsReference[key]?.answers[AnswerKey].content}`}
                </p>
              ))}
            </section>
          </div>
        );
      })}
    </section>
  );
};

export default AnswersList;
