import React, { ReactElement } from 'react';
import { Select, MenuItem } from '@mui/material';
import { SharedResultsProps } from '../../TrlMrlCalcInterface';
import { READINESS_LEVELS, ANSWERS_OVERVIEW, SUGGESTED_NEXT_STEPS } from '../../utils/helper';

interface FilteredOptionsPros extends SharedResultsProps {
  handleSelection: (answerNextQuestion: string) => void;
}

export function FilteredOptions({
  answersOverviewSec,
  nextPageSec,
  handleSelection,
}: FilteredOptionsPros): ReactElement | null {
  return (
    <Select
      name="sectionNav"
      sx={{ width: '100%' }}
      onChange={event => handleSelection(event.target.value)}
      defaultValue={READINESS_LEVELS}
    >
      <MenuItem value={READINESS_LEVELS}>{READINESS_LEVELS}</MenuItem>
      {answersOverviewSec && <MenuItem value={ANSWERS_OVERVIEW}>{ANSWERS_OVERVIEW}</MenuItem>}
      {nextPageSec && <MenuItem value={SUGGESTED_NEXT_STEPS}>{SUGGESTED_NEXT_STEPS}</MenuItem>}
    </Select>
  );
}

export default FilteredOptions;
