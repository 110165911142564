import image1 from '../assets/hrl-example-images/Calc-Examples-01.png';
import image2 from '../assets/hrl-example-images/Calc-Examples-02.png';
import image3 from '../assets/hrl-example-images/Calc-Examples-03.png';
import image4 from '../assets/hrl-example-images/Calc-Examples-04.png';
import image5 from '../assets/hrl-example-images/Calc-Examples-05.png';
import image6 from '../assets/hrl-example-images/Calc-Examples-06.png';
import image7 from '../assets/hrl-example-images/Calc-Examples-07.png';
import image8 from '../assets/hrl-example-images/Calc-Examples-08.png';
import image9 from '../assets/hrl-example-images/Calc-Examples-09.png';

const inDepthSteps = [
  {
    title: 'Human Use Requirements',
    description: 'To what degree have requirements for human use been identified and incorporated into the system?',
    sections: [
      {
        title: 'Human Use Requirements Determined',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            level: 1,
            image: image1,
            description: [
              'Basic human research has been conducted',
              'Key human behaviors, capabilities, and limitations identified',
            ],
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            level: 2,
            image: image2,
            description: ['Human-centered design principles, standards and guidance established'],
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            level: 3,
            image: image3,
            description: [
              'Human performance requirements flowed into system requirements',
              'Human systems experts engaged',
            ],
          },
        ],
      },
      {
        title: 'Conformance with Human Use Requirments Tested in',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: ['Preliminary designs', 'Modeling, part-task testing or trade studies'],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: ['Prototype designs', 'Mission-relevant part-task simulations', 'Relevant user populations'],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: [
              'Fully functional design',
              'High-fidelity, simulation or actual environment',
              'Relevant user populations',
            ],
          },
        ],
      },
      {
        title: 'Conformance with Human Use Requirments Verified in',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: [
              'Actual system hardware & software',
              'Operational environment',
              'Representative users',
              'Full range of usage scenarios and tasks',
            ],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: ['Final system hardware & software', 'Mission operational conditions', 'Representative users'],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Final system hardware & sofware',
              'Used across all operational conditions',
              'Full user population',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Testing',
    description:
      'Have tools for testing system design been developed, appropriate human performance metrics identified, and the full range of human-use scenarios been considered?',
    sections: [
      {
        title: 'Human Use Scenarios and Metrics Determined',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            image: image1,
            level: 1,
            description: ['Preliminary use scenarios'],
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            image: image2,
            level: 2,
            description: ['Basic task descriptions for user roles', 'Appropriate human performance metrics identified'],
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            image: image3,
            level: 3,
            description: [
              'Usage scenarios updated based on user needs analysis',
              'Feasibility and appropriateness of metrics determined through testing',
            ],
          },
        ],
      },
      {
        title: 'Tools, Metrics and Scenarios to Support Human Testing Developed for',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: [
              'Analytical tools, models, and prototypes for human systems design concepts',
              'Usage scenarios updated based on results',
              'Human performance metrics collected',
            ],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: [
              'Prototype designs of human-system interface',
              'Mission-relevant part-task simulations',
              'Usage scenarios updated based on results',
              'Human performance metrics collected',
            ],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: [
              'Fully functional design of human-system interface',
              'High-fidelity, simulation or actual environment',
              'Full range of use scenarios',
              'Human performance metrics collected',
            ],
          },
        ],
      },
      {
        title: 'Tools, Metrics and Scenarios to Support Human Testing Developed for',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: [
              'Actual system hardware & software',
              'Operational environment',
              'Full range of use scenarios',
              'Human performance metrics collected & criteria met',
            ],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: [
              'Final system hardware & software',
              'Mission operational conditions',
              'Full range of use scenarios',
              'Human performance metrics collected & criteria met',
            ],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Human systems performance data and lessons learned being collected',
              'Potential system changes and upgrades to address human performance problems being evaluated',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Design',
    description:
      'To what degree have system design strategies to support human use been developed and tested for each system role including a consideration of environmental impacts and human-machine teaming?',
    sections: [
      {
        title: 'Preliminary Human Interface Design Strategies Determined',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            level: 1,
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            level: 2,
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            image: image3,
            level: 3,
            description: [
              'Environmental conditions, constraints, and impacts on human performance analyzed',
              'Preliminary design features to accommodate human capabilities, limitations, and needs determined',
              'Candidate human-machine function allocations evaluated',
            ],
          },
        ],
      },
      {
        title: 'Effectiveness of Human Interface Design Strategies Evaluated and Updated in',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: [
              'Design strategies to support human use identified and incorporated',
              'Strategies to address environmental impacts on human peformance identified',
              'Human-machine teaming and function allocations updated',
            ],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: [
              'Design strategies to support human use updated',
              'Strategies to address environmental impacts on human performace updated',
              'Human-machine teaming and function allocations updated',
            ],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: [
              'Design strategies to support human use updated',
              'Strategies to address environmental impacts on human performace updated',
              'Human-machine teaming and function allocations updated',
            ],
          },
        ],
      },
      {
        title: 'Effectiveness of Human Interface Design Strategies Verified in',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: [
              'Design strategies to support human use incorporated in development system',
              'Strategies to address environmental impacts on human performance verified',
              'Human-machine teaming and function allocations updated',
            ],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: [
              'Human use issues all satisfactorily resolved',
              'Strategies to address environmental impacts on human performance verified',
              'Human-machine teaming and function allocations verified',
            ],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Human-system interface design, including strategies for supporting human-machine teaming and its ability to support human use across the full range of environmental and mission conditions, successfully tested and implemented',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Safety',
    description:
      'To what degree have key human performance issues and risks been identified and addressed, have relevant safety analyses been completed and are safety issues being addressed?',
    sections: [
      {
        title: 'Safety and Performance Risks Understood',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            image: image1,
            level: 1,
            description: ['Potential human performance issues and risks identified'],
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            image: image2,
            level: 2,
            description: [
              'Human performance issues identified based on analysis of operational and system demands',
              'Potential sources of human error and misuse identified',
            ],
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            image: image3,
            level: 3,
            description: ['Initial safety analyses for human users completed'],
          },
        ],
      },
      {
        title: 'Strategies to Mitigate Risks Identified and Tested in',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: [
              'Human performance risk analyses and trade-studies completed',
              'Risk mitigation strategies tested in modeling, part-task testing or trade studies',
            ],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: ['Risk mitigation strategies updated and tested in mission-relevant part-task simulations'],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: [
              'Risk mitigation strategies updated and tested in high-fidelity, simulation or actual environment',
            ],
          },
        ],
      },
      {
        title: 'Effectiveness of Risk Mitigation Strategies Verified in',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: [
              'Actual system hardware & software',
              'Operational environment',
              'System to track and resolve human systems issues after fielding evaluated',
            ],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: [
              'Final system hardware & software',
              'Mission operational conditions',
              'System to track and resolve human systems issues after fielding finalized',
            ],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Final system hardware & software',
              'Across all operational conditions',
              'System to track and resolve human systems issues in use',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Tasks and Procedures',
    description:
      'To what degree have analyses of the human cognitive and physical performance of tasks been completed and have procedures for safe and effective human use been developed?',
    sections: [
      {
        title: 'Tasks Understood',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            level: 1,
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            level: 2,
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            image: image3,
            level: 3,
            description: [
              'Cognitive task analyses and function and task analyses for each user role completed',
              'Situation awareness information flow and sharing requirements across teams of human or automated system components determined',
            ],
          },
        ],
      },
      {
        title: 'Task Analyses and Procedures Developed Through',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: [
              'Task analyses updated',
              'Procedures developed for human user roles throughout the lifecycle',
            ],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: ['Task analyses updated', 'Procedures updated for human user roles throughout the lifecycle'],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: [
              'Task analyses updated',
              'Procedures finalized for human user roles throughout the lifecycle',
            ],
          },
        ],
      },
      {
        title: 'Task Analyses and Procedures Verified and Updated Based on',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: [
              'Actual system hardware & software',
              'Operational environment',
              'Procedures all tested and updated',
            ],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: [
              'Final system hardware & software',
              'Mission operational conditions',
              'Procedures all found acceptable',
            ],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Final system hardware & software',
              'Across all operational conditions',
              'Procedures fully tested and implemented',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Manpower, Personnel, and Training',
    description:
      'To what degree have key manpower, personnel and training requirements to support system use been developed and evaluated?',
    sections: [
      {
        title: 'Manpower, Personnel and Training Requirements Understood',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            level: 1,
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            level: 2,
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            image: image3,
            level: 3,
            description: ['Initial manpower, personnel and training analyses completed'],
          },
        ],
      },
      {
        title: 'Strategies for Manpower, Personnel and Training Tested in',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: [
              'Strategies to accommodate manpower, personnel, and training concerns identified and recommended',
            ],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: ['Strategies to accommodate manpower, personnel, and training concerns tested and updated'],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: ['Strategies to accommodate manpower, personnel, and training concerns tested and updated'],
          },
        ],
      },
      {
        title: 'Effectiveness of Manpower, Personnel and Training Verified in',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: ['Strategies to accommodate manpower, personnel, and training concerns verified'],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: [
              'Strategies to accommodate manpower, personnel, and training concerns fully implemented and verified',
            ],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Systems and processess to support manpower, personnel, and training fully implemented and verified',
              'System to track and resolve human systems issues in the fielded system supports MPT issues',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Other Human-systems Integration (HSI) Domains',
    description:
      'To what degree have maintenance and sustainment requirements been addressed, as well as other relevant HSI domains (such as force protection and survivability, habitability or occupational health) if applicable?',
    sections: [
      {
        title: 'Other HSI Domain Requirements Understood',
        inputs: [
          {
            heading: 'Basic Capabilities & Limits',
            level: 1,
          },
          {
            heading: 'Human Centered Guidlines & Standards',
            level: 2,
          },
          {
            heading: 'Human Centered Requirements & Analyses',
            image: image3,
            level: 3,
            description: [
              'Initial analyses to address human interactions during maintenance and sustainment completed',
              'Initial analyses for other relevant HSI domains completed',
            ],
          },
        ],
      },
      {
        title: 'Strategies for Other HSI Domains Tested in',
        inputs: [
          {
            heading: 'Modeling and Low Fidelity Testing',
            image: image4,
            level: 4,
            description: [
              'Strategies to address human interactions during maintenance and sustainment identified and recommended',
              'Strategies to address other relevant HSI domains identified and reommended',
            ],
          },
          {
            heading: 'Mission Relevant Part Task Simulations',
            image: image5,
            level: 5,
            description: [
              'Strategies to address human interactions during maintenance and sustainment tested and updated',
              'Strategies to address other relevant HSI domains tested and updated',
            ],
          },
          {
            heading: 'High Fidelity Simulations',
            image: image6,
            level: 6,
            description: [
              'Strategies to address human interactions during maintenance and sustainment tested and updated',
              'Strategies to address other relevant HSI domains tested and updated',
            ],
          },
        ],
      },
      {
        title: 'Effectiveness of Strategies for Other HSI Domains Verified in',
        inputs: [
          {
            heading: 'Testing in Operational Environment',
            image: image7,
            level: 7,
            description: [
              'Strategies to address human interactions during maintenance and sustainment verified',
              'Strategies to address other relevant HSI domains verified',
            ],
          },
          {
            heading: 'Fully Tested Across Mission Operations',
            image: image8,
            level: 8,
            description: [
              'Strategies to address human interactions during maintenance and sustainment verified',
              'Strategies to address other relevant HSI domains fully implemented and verified',
            ],
          },
          {
            heading: 'Full Operations with Systematic Monitoring',
            image: image9,
            level: 9,
            description: [
              'Systems and processess to support human interactions during maintenance and sustainment and other relevant HSI domains fully implemented and verified',
              'System to track and resolve human systems issues in the fielded system supports these activities',
            ],
          },
        ],
      },
    ],
  },
];

export default inDepthSteps;
