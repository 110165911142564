import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import './answersForm.scss';
import { Store } from '../../store/reducers';
import { useSelector } from 'react-redux';
import {
  AnswersFormProps,
  ANSWER_INDEX,
  addTrlOrMrlScore,
  isTrlQuestion,
  BackButtonClicked,
  ANSWER_KEY,
  SelectedAnswersArray,
  UpdateQuestionsAnswered,
} from './AnswerFormUtils';
import { PRODUCTION_FULL_OPERATIONAL_TESTING, FIND_MRL_START, AnswerKey, HRL_9 } from '../../data/questions';
import { IDENTIFY_MRL_STEP } from '../../data/nextSteps';
import CustomTextField from '../../components/shared/CustomTextField';

const SingleSelectAnswerForm = ({
  currentQuestion,
  onQuestionAnswered,
  onMRLScoreReceived,
  onHRLScoreReceived,
  questions,
  changeQuestion,
  updateAnswered,
  updateMrlAnswersAndAddScore,
  updateTrlAnswersAndAddScore,
  updateHrlAnswersAndAddScore,
}: AnswersFormProps): ReactElement | null => {
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onSubmit',
  });

  const { trlScore, trlQuestionsAnswered, mrlQuestionsAnswered, hrlQuestionsAnswered, currentActive } = useSelector(
    (state: Store) => state.TrlMrlCalculator
  );
  const [selectedAnswers, setSelectedAnswers] = useState<string[] | null>(null);
  // const [currentAnswer, setCurrentAnswer] = useState(trlQuestionsAnswered[currentQuestion]);
  const [currentAnswer, setCurrentAnswer] = useState<any>();
  const [answersArray, setAnswersArray] = useState<any>();
  const [currentQuestionContent, setCurrentQuestionContent] = useState('');

  useEffect(() => {
    setCurrentAnswer(undefined)
  },[currentQuestionContent])

  useEffect(() => {
    let nextQuestion: string;
    if (currentQuestion === FIND_MRL_START) nextQuestion = IDENTIFY_MRL_STEP[trlScore];
    else nextQuestion = currentQuestion;

    if (trlQuestionsAnswered[currentQuestion] !== undefined) setCurrentAnswer(trlQuestionsAnswered[currentQuestion]);
    else setCurrentAnswer(hrlQuestionsAnswered[currentQuestion]);
    setAnswersArray(Object.entries(questions[nextQuestion]?.answers));

    setCurrentQuestionContent(questions[nextQuestion]?.content);
    changeQuestion(nextQuestion);
  }, [currentQuestion]);

  useEffect(() => {
    if (currentActive) {
      setCurrentQuestionContent(questions[currentActive].content);
      setAnswersArray(Object.entries(questions[currentActive]?.answers));
    }
  }, [currentActive]);

  const previouslySelectedAnswersArray = SelectedAnswersArray({
    trlQuestionsAnswered: trlQuestionsAnswered,
    mrlQuestionsAnswered: mrlQuestionsAnswered,
    hrlQuestionsAnswered: hrlQuestionsAnswered,
    currentQuestion: currentActive,
  });

  let answerInput = isTrlQuestion(currentQuestion)
    ? trlQuestionsAnswered[currentQuestion]
    : hrlQuestionsAnswered[currentActive];

  const backButtonClicked = () => {
    //change question
    BackButtonClicked({
      currentQuestion: currentActive,
      changeQuestion: changeQuestion,
      trlQuestionsAnswered: trlQuestionsAnswered,
      hrlQuestionsAnswered: hrlQuestionsAnswered,
      mrlQuestionsAnswered: mrlQuestionsAnswered,
    });
    reset();
  };

  useEffect(() => {
    setSelectedAnswers(previouslySelectedAnswersArray);
  }, [currentActive]);

  const currentLength = isTrlQuestion(currentQuestion) ? Object.keys(trlQuestionsAnswered).length : Object.keys(hrlQuestionsAnswered).length;

  const latestCount =
    answerInput?.yes?.questionNum ||
    answerInput?.no?.questionNum ||
    currentLength + 1;

  const onSubmit = (data: any) => {
    let dataKey = '';
    const nextQuestion: string[] = [];
    const returnObject: any = isTrlQuestion(currentQuestion) ? trlQuestionsAnswered : hrlQuestionsAnswered;
    returnObject[currentActive] = {};

    answersArray.forEach(([key, value]: [string, AnswerKey]) => {
      let inputText = '';
      if (Object.entries(data).length == 2) {
        const text = Object.entries(data)[1][1];
        inputText = typeof text === 'string' ? text : '';
      }

      if (value.content === (data[currentActive] || currentAnswer) && currentQuestion !== FIND_MRL_START) {
        dataKey = key;
        returnObject[currentActive][dataKey] = {
          inputText,
          questionNum: latestCount,
        };
        if (value.nextQuestion) {
          nextQuestion.push(value.nextQuestion);

          UpdateQuestionsAnswered({
            currentQuestion: currentActive,
            trlQuestionsAnswered: trlQuestionsAnswered,
            mrlQuestionsAnswered: mrlQuestionsAnswered,
            hrlQuestionsAnswered: hrlQuestionsAnswered,
            nextQuestion: nextQuestion,
            changeQuestion: changeQuestion,
            updateAnswered: updateAnswered,
            currentAnswer: currentAnswer,
            setSelectedAnswers: setSelectedAnswers,
            onQuestionAnswered: onQuestionAnswered,
            returnObject: returnObject,
          });
        } else {
          addTrlOrMrlScore({
            value: value,
            nextQuestion: nextQuestion,
            returnObject: returnObject,
            currentQuestion: currentActive,
            dataKey: dataKey,
            inputText,
            setSelectedAnswers: setSelectedAnswers,
            onMRLScoreReceived: onMRLScoreReceived,
            onHRLScoreReceived: onHRLScoreReceived,
            singleSelectForm: true,
            trlQuestionsAnswered: trlQuestionsAnswered,
            mrlQuestionsAnswered: mrlQuestionsAnswered,
            hrlQuestionsAnswered: hrlQuestionsAnswered,
            updateTrlAnswersAndAddScore: updateTrlAnswersAndAddScore,
            updateMrlAnswersAndAddScore: updateMrlAnswersAndAddScore,
            updateHrlAnswersAndAddScore: updateHrlAnswersAndAddScore,
          });
        }
      }
    });

    reset();
  };

  const updateValue = (value: any) => {

    const lowerVal = value.toLowerCase();
    const isCurrentAnswerAnObject = typeof currentAnswer === 'object';
    const currentAnswerKey = isCurrentAnswerAnObject ? Object.keys(currentAnswer)[0].toLowerCase() : false;

    if (
      (currentAnswerKey && lowerVal !== currentAnswerKey) ||
      (!isCurrentAnswerAnObject && lowerVal !== currentAnswer)
    ) {
      setCurrentAnswer(value);
    }
  };

  if (answersArray)
    return (
      <form
        className="answersForm"
        data-testid="answersForm"
        onSubmit={handleSubmit(onSubmit)}
        onError={e => {
          console.log(e);
        }}
      >
        <h6>QUESTION {latestCount}</h6>
        <h4>{currentQuestionContent}</h4>
        {answersArray.map((element: any, index: number) => {
          const currentContent = element[ANSWER_INDEX].content;
          const currentContentIsCurrentAnswer = currentContent === currentAnswer;
          const currentContentInSelectedAnswers =
            selectedAnswers?.length === 1 && currentContent === selectedAnswers[ANSWER_KEY];
          const isInputRequired = element[ANSWER_INDEX].inputRequired;
          const input = answerInput !== undefined ? answerInput[element[ANSWER_KEY]]?.inputText : '';
          const inputText =
            typeof currentAnswer === 'object' && currentAnswer[currentContent.toLowerCase()]
              ? currentAnswer[currentContent.toLowerCase()].inputText
              : false;
          return (
            <Box
              key={index}
              sx={{
                button: {
                  ':hover': { borderColor: 'primary.main' },
                  '&.radio-button-selected': { borderColor: 'primary.main' },
                },
              }}
            >
              <button
                type="button"
                className={`radio-button ${
                  currentContentIsCurrentAnswer || currentContentInSelectedAnswers ? 'radio-button-selected' : ''
                }`}
                onClick={() => updateValue(currentContent)}
                name={currentActive}
              >
                <Box component="label" className="radio radio-gradient" sx={{ color: 'primary.main' }}>
                  <span className="radio__input">
                    <input
                      { ...register(currentActive)}
                      type="radio"
                      value={currentContent}
                      checked={currentContentIsCurrentAnswer || currentContentInSelectedAnswers}
                      onChange={() => updateValue(currentContent)}
                      name={currentActive}
                    />
                    <span className="radio__control"></span>
                  </span>
                  <span className="radio__label">{currentContent}</span>
                </Box>
              </button>
              <div className="answer">
                {isInputRequired && (currentContentIsCurrentAnswer || inputText) && (
                  <CustomTextField
                    multiline
                    required
                    control={control}
                    errors={errors}
                    defaultValue={input}
                    placeholder="Enter details here"
                    name="Input"
                    rows={5}
                    label="Describe testing conditions and results. Include quantitative performance metrics if available and indicate whether the testing conditions were consistent with use in operational military environments. *"
                    sx={{ label: { fontWeight: 600, fontSize: '0.8em' } }}
                  />
                )}
              </div>
            </Box>
          );
        })}

        <div className="answersForm__buttons">
          {currentQuestion !== PRODUCTION_FULL_OPERATIONAL_TESTING && currentActive !== HRL_9 && (
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              onClick={backButtonClicked}
              className="answersForm__buttons__button"
              sx={{ marginRight: '0.5rem' }}
            >
              Back
            </Button>
          )}
          <Button variant="contained" type="submit" disabled={!currentAnswer}>
            Next
          </Button>
        </div>
      </form>
    );

  return null;
};

export default SingleSelectAnswerForm;
