/* eslint-disable react/require-default-props */
import React, { ReactElement, ReactNode } from 'react';
import { Box, InputLabel, StandardTextFieldProps, SxProps, TextField } from '@mui/material';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

export interface CustomTextFieldProps extends StandardTextFieldProps {
  label: ReactNode;
  defaultValue: string;
  control: Control<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  maxLength?: number;
  children?: ReactNode;
  rows?: number;
  sx?: SxProps;
  onValueChange?: (e: any) => void;
}

export const CustomTextField = ({
  label,
  maxLength,
  children,
  placeholder,
  autoFocus,
  onValueChange,
  defaultValue,
  errors,
  control,
  rows,
  sx,
  ...props
}: CustomTextFieldProps): ReactElement => {
  const { name, multiline, required, inputProps, InputLabelProps, disabled } = props;
  const customInputProps = inputProps || {};

  if (!customInputProps['data-testid']) {
    customInputProps['data-testid'] = name;
  }

  if (maxLength && maxLength > 0) {
    customInputProps.maxLength = maxLength;
  }

  const customInputLabelProps = InputLabelProps || {};
  if (required === true) {
    customInputLabelProps.className = 'required-label';
    customInputLabelProps.required = required;
  }

  return (
    <Controller
      control={control}
      name={name || ''}
      // eslint-disable-next-line object-shorthand
      rules={{ required: required }}
      defaultValue={defaultValue}
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      render={({
        // eslint-disable-next-line no-shadow
        field: { onChange, onBlur, value, name, ref },
      }) => (
        <Box sx={{ width: '100%', whiteSpace: 'unset', ...sx }}>
          <InputLabel htmlFor={name} error={!!errors[name || '']} style={{ width: '100%', whiteSpace: 'unset' }}>
            {label} {children}
            <Box sx={{ mt: '0.5rem' }}>
              <TextField
                multiline={multiline}
                onChange={event => {
                  const inputValue = event.target.value;
                  onChange(inputValue);
                  if (onValueChange) {
                    onValueChange(inputValue);
                  }
                }}
                error={!!errors[name || '']}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                onBlur={onBlur}
                value={value || ''}
                variant="outlined"
                minRows={rows}
                size={multiline ? 'medium' : 'small'}
                required={required}
                style={{ width: '100%' }}
                fullWidth
                inputProps={{
                  ref,
                  ...customInputProps,
                }}
                InputLabelProps={customInputLabelProps}
              />
            </Box>
          </InputLabel>
        </Box>
      )}
    />
  );
};

CustomTextField.defaultProps = {
  maxLength: 0,
  sx: null,
  rows: 1,
};

export default CustomTextField;
