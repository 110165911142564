import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

interface CustomRadioFieldProps {
  name: string;
  value: string;
  onCheck: () => void;
  checked: boolean;
  label?: string;
}

const CustomRadioField = ({ name, value, onCheck, checked, label }: CustomRadioFieldProps): ReactElement => {
  return (
    <Box
      component="button"
      type="button"
      className={`radio-button ${checked ? 'radio-button-selected' : ''}`}
      onClick={onCheck}
      name={name}
      sx={{
        '::hover': { borderColor: 'primary.main' },
        '&.radio-button-selected': { borderColor: 'primary.main' },
      }}
    >
      <Box
        component="label"
        className="radio radio-gradient"
        sx={{ color: 'primary.main', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <span className="radio__input">
          <input type="radio" value={value} checked={checked} onChange={onCheck} name={name} />
          <span className="radio__control" />
        </span>
        <Typography component="span" className="radio__label" sx={{ fontWeight: 700 }}>
          {label || name}
        </Typography>
      </Box>
    </Box>
  );
};

CustomRadioField.defaultProps = {
  label: null,
};
export default CustomRadioField;
