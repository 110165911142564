import React, { ReactElement, useState } from 'react';
import './card.scss';
import CardInfoModal from '../modal/CardInfoModal';
import { useWindowSize, TABLE_WIDTH } from '../../utils/helper';

interface CardProps {
  title: string;
  imagePath: string;
  subtitle: string;
  className: string;
  onCancel?: () => void;
}

export const Card = ({ title, subtitle, imagePath, className }: CardProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const openModal = (): void => setOpen(true);
  const closeModal = (): void => {
    setOpen(false);
  };
  const [width] = useWindowSize();
  const mobile = !(width >= TABLE_WIDTH);
  const isCardModal = className === 'card modal card__modal';
  return (
    <div className={className} data-testid="card" {...(!isCardModal && { onClick: (): void => openModal() })}>
      <div className="card-image">
        <img src={imagePath} alt="TRL example" />
      </div>
      <div className="card-content">
        <div className="card-content_title">
          {title}
          <CardInfoModal isOpen={open} onCancel={closeModal} title={title} imagePath={imagePath} subtitle={subtitle} />
        </div>
        {(isCardModal || mobile) && <p className="card-content_subtitle">{subtitle}</p>}
      </div>
    </div>
  );
};

Card.defaultProps = {
  onCancel: null,
};
export default Card;
