// why do this? Removes potential misspelling and duplicate declaration of types
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_SECTION = 'CHANGE_SECTION';

// why do this? Makes dispatching a store actions easy and look like a function inside a component
export const changePage = (currentPage: string): any => ({
  type: CHANGE_PAGE,
  payload: { currentPage },
});

export const changeSection = (currentSecResultsPage: string): any => ({
  type: CHANGE_SECTION,
  payload: { currentSecResultsPage },
});
