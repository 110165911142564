import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/index';
import { loadState } from './middleware';

const persistedState = loadState(); // get localStorage state
const initialState = persistedState || {};

export interface Action {
  type: string;
  payload: any;
}

const composeEnhancers =
  process.env.NODE_ENV !== 'development' ? compose() : composeWithDevTools({ trace: true, traceLimit: 10 });

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware()));
export default store;
