import React, { ReactElement, useEffect, useState } from 'react';
import { Tab, TabProps } from './Tab';
import './Tabs.scss';
import '../legend/Legend.scss';
import { useWindowSize, TABLE_WIDTH } from '../../utils/helper';

interface TabsProps {
  children: React.ReactElement<TabProps>[];
  onSelectedTab?: (index: number) => void;
  activeTabIndex?: number;
}

export const Tabs = ({ onSelectedTab, children, activeTabIndex }: TabsProps): ReactElement => {
  const [currentItem, setCurrentItem] = useState(activeTabIndex === undefined ? 0 : activeTabIndex);
  const [width] = useWindowSize();
  const mobile = !(width >= TABLE_WIDTH);

  const tabSelected = (index: number): void => {
    setCurrentItem(index);
    if (onSelectedTab) {
      onSelectedTab(index);
    }
  };

  useEffect(() => {
    if (activeTabIndex !== undefined) {
      setCurrentItem(activeTabIndex);
      if (onSelectedTab) {
        onSelectedTab(activeTabIndex);
      }
    }
  }, [activeTabIndex]);

  return (
    <div className="tabs" data-testid="tabs">
      <div className="tabs_items">
        <div className="tabs_header">
          {children.map((content, index: number) => (
            <div key={index}>
              <Tab
                key={index}
                active={currentItem === index}
                title={content.props.title}
                visible={content.props.visible}
                index={index}
                showDivider={children.length !== index + 1}
                onSelected={(): void => tabSelected(index)}
              />
            </div>
          ))}
        </div>
        {!mobile && (
          <div className="Legend">
            <section className="Legend__group">
              <span className="Legend__group__dot Legend__group__dot__questions resColor" />
              <p>Research & Development</p>
            </section>
            <section className="Legend__group">
              <span className="Legend__group__dot Legend__group__dot__questions protoColor" />
              <p>Prototype</p>
            </section>
            <section className="Legend__group">
              <span className="Legend__group__dot Legend__group__dot__questions prodColor" />
              <p>Production</p>
            </section>
          </div>
        )}
      </div>
      <div className="tabs_content">{children[currentItem].props.children}</div>
    </div>
  );
};

Tabs.defaultProps = {
  onSelectedTab: null,
  activeTabIndex: 0,
};

export default Tabs;
