/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from '../../store/reducers';
import { changeSection } from '../../store/actions/Navigation';
import FilteredOptions from './FilteredOptions';
import {
  READINESS_LEVELS,
  ANSWERS_OVERVIEW,
  SUGGESTED_NEXT_STEPS,
  TABLE_WIDTH,
  useWindowSize,
} from '../../utils/helper';
import './SectionsNav.scss';
import { SharedResultsProps } from '../../TrlMrlCalcInterface';

export function SectionsNav({ answersOverviewSec, nextPageSec }: SharedResultsProps): ReactElement | null {
  const dispatch = useDispatch();
  const sections = [READINESS_LEVELS, ANSWERS_OVERVIEW, SUGGESTED_NEXT_STEPS];
  const { currentSecResultsPage } = useSelector((state: Store) => state.Navigation);
  const [width] = useWindowSize();

  const handleSelectionString = (section: string): void => dispatch(changeSection(section));

  if (width >= TABLE_WIDTH)
    return (
      <section className="SectionsNav">
        {(answersOverviewSec || nextPageSec) &&
          sections.map((section, index) => {
            if (
              section === READINESS_LEVELS ||
              (section === SUGGESTED_NEXT_STEPS && nextPageSec) ||
              (section === ANSWERS_OVERVIEW && answersOverviewSec)
            )
              return (
                <div
                  className="SectionsNav__group"
                  key={index}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(): void => handleSelectionString(section)}
                  onClick={(): void => handleSelectionString(section)}
                >
                  <p className={`SectionsNav__group-link ${currentSecResultsPage === section ? 'linkSelected' : ''}`}>
                    {section}
                  </p>
                  {index <= 1 && <p>|</p>}
                </div>
              );
            return null;
          })}
      </section>
    );

  return (
    <FilteredOptions
      answersOverviewSec={answersOverviewSec}
      nextPageSec={nextPageSec}
      handleSelection={handleSelectionString}
    />
  );
}

export default SectionsNav;
