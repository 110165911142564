import React, { ReactElement } from 'react';
import './ScoreRing.scss';

export interface ScoreRingProps {
  score: number;
  type: string;
  definition: string;
  layout: 'vertical' | 'horizontal';
}

const ScoreRing = ({ score, type, definition, layout }: ScoreRingProps): ReactElement | null => {
  const scoreRingText = (yAxis: string, fontsize: string, content: string): ReactElement => {
    return (
      <text
        x="50%"
        y={`${yAxis}%`}
        textAnchor="middle"
        fill="#364d6c"
        fontSize={`${fontsize}px`}
        fontFamily="Montserrat"
        fontWeight="600"
        dy=".3em"
      >
        {content}
      </text>
    );
  };

  if (score !== 0)
    return (
      <section className={`ScoreRingWrapper ${layout}`}>
        <svg className="ScoreRing" viewBox="0 0 40 40">
          <g className="Ring">
            <circle className="Background" cx="50%" cy="50%" strokeWidth="3" r="15.915" />
            <circle
              className={`${type}-completed`}
              cx="50%"
              cy="50%"
              strokeWidth="3"
              r="15.915"
              transform="rotate(-90 20 20)"
              style={{
                strokeDasharray: `${type === 'TRL' ? score * 10 + 10 : score * 10} 100`,
                strokeLinecap: 'round',
              }}
            />
          </g>
          {scoreRingText('45', '7', `${type === 'HRL-indepth' ? 'HRL' : type} ${score}`)}
          {scoreRingText('65', '5', `of ${type === 'TRL' ? '9' : '9'}`)}
        </svg>
        <div>
          {type === 'HRL' && <h3>Rapid Assessment</h3>}
          {type === 'HRL-indepth' && <h3>In-Depth Assessment</h3>}
          <p>{definition}</p>
        </div>
      </section>
    );
  return null;
};

export default ScoreRing;
