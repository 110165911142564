import React, { ReactElement } from 'react';

interface NextStepsListProps {
  type: string;
  score: number;
  nextSteps: string[];
}

const NextStepsList = ({ type, score, nextSteps }: NextStepsListProps): ReactElement | null => {
  const highestScore = type === 'TRL' ? 9 : 10;

  if (score > 0) {
    return (
      <section className="NextStepsList">
        <h4>{type} Suggested Next Steps</h4>
        <div className="NextStepsList__box">
          <h5 className={`NextStepsList__box__title NextStepsList__box__title-${type}`}>
            Steps to get from {type} {score} to {type} {highestScore}
          </h5>
          <ul className="NextStepsList__box__list">
            {nextSteps.map((step: string) => (
              <li key={step}>{step}</li>
            ))}
          </ul>
        </div>
      </section>
    );
  }

  return null;
};

export default NextStepsList;
