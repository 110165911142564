/* eslint-disable prettier/prettier */
import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, Typography, Grid } from '@mui/material';
import { HRL_TYPE, INDEPTH_HRL, RESULTS } from '../../utils/helper';
import { changePage } from '../../store/actions/Navigation';
import { Store } from '../../store/reducers';
import { SharedCalculatorProps, SharedResultsProps } from '../../TrlMrlCalcInterface';
import Header from '../../components/header/Header';
import { ReadinessLevelsSection } from '../sections';
import CustomRadioField from '../../components/shared/CustomRadioField';

interface ResultsProps extends SharedCalculatorProps, SharedResultsProps {}

const ResultsPreview = ({ displayRestartBttn, onRestart }: ResultsProps): ReactElement => {
  const dispatch = useDispatch();
  const [selectedNext, setNext] = useState('');
  const goToHrlTypePage = (): void => dispatch(changePage(HRL_TYPE));
  const { hrlScore } = useSelector((state: Store) => state.TrlMrlCalculator);
  const isHRLPreview = hrlScore > 0;

  const goToChosenNext = (): void => {
    if (selectedNext === 'in-depth') {
      dispatch(changePage(INDEPTH_HRL));
    } else if (selectedNext === 'results') {
      dispatch(changePage(RESULTS));
    }
  };

  return (
    <div className="Results" data-testid="results">
      <Header
        title="Are you sure you want to start a new assessment?"
        content="If you restart the assessment, all of the progress you have made will be lost."
        displayRestartBttn={displayRestartBttn}
        onRestart={onRestart}
      />
      <Grid container direction="row">
        <Grid item xs={12} md={8}>
          <ReadinessLevelsSection isPreview={false} isHRL={false} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ mr: 6, mt: 6 }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                Continue Assessment
              </Typography>
              {isHRLPreview ? (
                <>
                  <Typography variant="h3">Would you like to take the more in-depth HRL assessment?</Typography>
                  <Typography>
                    The in-depth assessment will ask more questions than the rapid assessement as well as ask for more
                    evidence. By taking the in-depth assessement, the results may be more accurate and provide more
                    insight.
                  </Typography>
                  <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomRadioField
                      name="I would like to take the in-depth assessment"
                      value="in-depth"
                      onCheck={(): void => setNext('in-depth')}
                      checked={selectedNext === 'in-depth'}
                    />
                    <CustomRadioField
                      name="I would like to go to the results page"
                      value="results"
                      onCheck={(): void => setNext('results')}
                      checked={selectedNext === 'results'}
                    />
                    <Button
                      variant="contained"
                      onClick={(): void => goToChosenNext()}
                      sx={{ alignSelf: 'flex-end', marginTop: '0.5em' }}
                    >
                      Next
                    </Button>
                  </CardActions>
                </>
              ) : (
                <>
                  <Typography gutterBottom>
                    Click the button below to continue onto{' '}
                    <Typography component="span" sx={{ fontWeight: 700 }}>
                      Human Readiness Level (HRL)
                    </Typography>
                  </Typography>
                  <CardActions>
                    <Button variant="contained" onClick={(): void => goToHrlTypePage()}>
                      Next
                    </Button>
                  </CardActions>
                </>
              )}
            </CardContent>
          </Card>
          {/* <Card sx={{ mr: 6, mt: 1 }}>
          <CardContent>
              <Typography variant="h3" gutterBottom>
                Go back to dashboard
              </Typography>
            <CardActions>
              <Button variant="contained" onClick={(): void => dispatch(changePage(WELCOME))}>
                Back
              </Button>
            </CardActions>
            </CardContent>
          </Card> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default ResultsPreview;
