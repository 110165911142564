import React, { useEffect, useCallback, ReactElement } from 'react';
// Redux store
import { useSelector, useDispatch } from 'react-redux';
import { Store } from './store/reducers';
import { WELCOME, RESULTS, QUESTIONS, RESULTS_PREVIEW, HRL_TYPE, INDEPTH_HRL, RAPID_HRL } from './utils/helper';
import { updateStore } from './store/actions/TrlMrlCalculator';
import { changePage } from './store/actions/Navigation';
// Components
import { SharedCalculatorProps, SharedResultsProps } from './TrlMrlCalcInterface';
import Welcome from './pages/welcome/Welcome';
import Questions from './pages/questions/Questions';
import Results from './pages/results/Results';
import ResultsPreview from './pages/results/ResultsPreview';
import HrlType from './pages/hrl/HrlType';
import InDepthAssessment from './pages/hrl/InDepthAssessment';

interface CalculatorProps extends SharedCalculatorProps, SharedResultsProps {}

const Calculator = ({
  returnData,
  displayRestartBttn,
  displayPdfBttn,
  answersOverviewSec,
  nextPageSec,
  onRestart,
  storeData,
  displayInstructions,
  homepageText,
  returnPageName,
}: CalculatorProps): ReactElement => {
  const dispatch = useDispatch();
  const { currentPage } = useSelector((state: Store) => state.Navigation);
  const {
    trlScore,
    trlDefinition,
    hrlScore,
    hrlDefinition,
    mrlScore,
    mrlDefinition,
    trlNextSteps,
    mrlNextSteps,
    hrlNextSteps,
    dateCompleted,
    currentActive,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    hrlQuestionsAnswered,
    environmentInputs,
    level1HrlData,
    level2HrlData,
    level3HrlData,
    level4HrlData,
    level5HrlData,
    level6HrlData,
    level7HrlData,
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore,
  } = useSelector((state: Store) => state.TrlMrlCalculator);

  const returnOnChange = useCallback(() => {
    const value = {
      trlScore,
      trlDefinition,
      hrlScore,
      hrlDefinition,
      mrlScore,
      mrlDefinition,
      trlNextSteps,
      mrlNextSteps,
      hrlNextSteps,
      dateCompleted,
      currentActive,
      trlQuestionsAnswered,
      mrlQuestionsAnswered,
      hrlQuestionsAnswered,
      environmentInputs,
      level1HrlData,
      level2HrlData,
      level3HrlData,
      level4HrlData,
      level5HrlData,
      level6HrlData,
      level7HrlData,
      level1HrlScore,
      level2HrlScore,
      level3HrlScore,
      level4HrlScore,
      level5HrlScore,
      level6HrlScore,
      level7HrlScore,
    };
    if (returnData) returnData(value);
  }, [
    trlScore,
    trlDefinition,
    hrlScore,
    hrlDefinition,
    mrlScore,
    mrlDefinition,
    trlNextSteps,
    mrlNextSteps,
    hrlNextSteps,
    dateCompleted,
    currentActive,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    hrlQuestionsAnswered,
    environmentInputs,
    level1HrlData,
    level2HrlData,
    level3HrlData,
    level4HrlData,
    level5HrlData,
    level6HrlData,
    level7HrlData,
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore,
  ]);

  useEffect(() => {
    returnOnChange();
  }, [
    trlScore,
    trlDefinition,
    mrlScore,
    mrlDefinition,
    hrlScore,
    hrlDefinition,
    trlNextSteps,
    mrlNextSteps,
    hrlNextSteps,
    dateCompleted,
    currentActive,
    trlQuestionsAnswered,
    mrlQuestionsAnswered,
    hrlQuestionsAnswered,
    environmentInputs,
    level1HrlData,
    level2HrlData,
    level3HrlData,
    level4HrlData,
    level5HrlData,
    level6HrlData,
    level7HrlData,
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore,
  ]);

  const isEmpty = (data: object): boolean => Object.keys(data).length === 0;

  useEffect(() => {
    if (storeData) {
      dispatch(updateStore(storeData));
      if (isEmpty(storeData)) dispatch(changePage(WELCOME));
      else if (storeData.dateCompleted) dispatch(changePage(RESULTS));
      else dispatch(changePage(QUESTIONS));
    }
  }, [storeData]);

  useEffect(() => {
    if (returnPageName) returnPageName(currentPage);
  }, [currentPage]);

  return (
    <div className="calculator" data-testid="calculator">
      {currentPage === WELCOME && <Welcome displayInstructions={displayInstructions} homepageText={homepageText} />}
      {currentPage === HRL_TYPE && <HrlType displayRestartBttn={displayRestartBttn} onRestart={onRestart} />}
      {currentPage === RAPID_HRL && (
        <Questions
          displayRestartBttn={displayRestartBttn}
          displayPdfBttn={false}
          onRestart={onRestart}
          showExamples
          isHRL
        />
      )}
      {currentPage === QUESTIONS && (
        <Questions
          displayRestartBttn={displayRestartBttn}
          displayPdfBttn={displayPdfBttn}
          onRestart={onRestart}
          showExamples
        />
      )}
      {currentPage === INDEPTH_HRL && (
        <InDepthAssessment displayRestartBttn={displayRestartBttn} onRestart={onRestart} />
      )}
      {currentPage === RESULTS_PREVIEW && (
        <ResultsPreview
          displayRestartBttn={displayRestartBttn}
          answersOverviewSec={answersOverviewSec}
          nextPageSec={nextPageSec}
          onRestart={onRestart}
        />
      )}
      {currentPage === RESULTS && (
        <Results
          displayRestartBttn={displayRestartBttn}
          displayPdfBttn={displayPdfBttn}
          answersOverviewSec={answersOverviewSec}
          nextPageSec={nextPageSec}
          onRestart={onRestart}
        />
      )}
    </div>
  );
};

export default Calculator;
