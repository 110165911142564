import React, { ReactElement } from 'react';
import { AccordionDetails, Card, CardContent, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const IndepthHrlResultsDetails = ({ answers }): ReactElement => {
  const determineIcon = (data: string): ReactElement => {
    if (data === 'completed') {
      return <CheckCircleIcon color="secondary" fontSize="large" />;
    }
    if (data === 'partially completed') {
      return <RotateLeftIcon fontSize="large" />;
    }
    return <HighlightOffIcon fontSize="large" />;
  };
  return (
    <AccordionDetails sx={{ display: 'flex', gap: '0.5em', flexWrap: 'wrap' }}>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[1])}
          <Typography sx={{ fontWeight: 700 }}>Basic Capabilities & Limits</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[2])}
          <Typography sx={{ fontWeight: 700 }}>Human Centered Guidelines & Standards</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[3])}
          <Typography sx={{ fontWeight: 700 }}>Human Centered Guidelines & Analyses</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[4])}
          <Typography sx={{ fontWeight: 700 }}>Modeling and Low Fidelity Testing</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[5])}
          <Typography sx={{ fontWeight: 700 }}>Mission Relevant Part Task Simulations</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[6])}
          <Typography sx={{ fontWeight: 700 }}>High Fidelity Simulations</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[7])}
          <Typography sx={{ fontWeight: 700 }}>Testing in Operational Environment</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[8])}
          <Typography sx={{ fontWeight: 700 }}>Fully Tested Across Mission Operations</Typography>
        </CardContent>
      </Card>
      <Card sx={{ flexBasis: '30%' }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', flexBasis: '33%' }}>
          {determineIcon(answers[9])}
          <Typography sx={{ fontWeight: 700 }}>Full Operations with Systematic Monitoring</Typography>
        </CardContent>
      </Card>
    </AccordionDetails>
  );
};

export default IndepthHrlResultsDetails;
