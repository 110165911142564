/* eslint-disable react/require-default-props */
/* eslint-disable global-require */
import React, { ReactElement } from 'react';
// Redux store
import { useSelector } from 'react-redux';
import { Store } from '../../store/reducers';
// Components
import Header from '../../components/header/Header';
import Answers from '../../components/questions/Answers';
import Definition from '../../components/definition/Definition';
import { MRL_QUESTIONS, TRL_QUESTIONS, HRL_QUESTIONS, HRL_9 } from '../../data/questions';
import ProgressBar from '../../components/progressBar/ProgressBar';
import ExampleContent from '../../components/exampleContent/ExampleContent';
// Helpers
import { SharedCalculatorProps, SharedResultsProps } from '../../TrlMrlCalcInterface';
// Styles
import './Questions.scss';

interface QuestionsProps extends SharedCalculatorProps, SharedResultsProps {
  showExamples?: boolean;
  isHRL?: boolean;
}

const Questions = ({ displayRestartBttn, onRestart, showExamples, isHRL }: QuestionsProps): ReactElement => {
  const { currentActive } = useSelector((state: Store) => state.TrlMrlCalculator);

  const currentQuestion = isHRL ? HRL_9 : currentActive;

  const isATRLQuestion = currentQuestion in TRL_QUESTIONS;
  let progress;
  let questions;
  let definitions;

  if (isATRLQuestion) {
    progress = TRL_QUESTIONS[currentActive]?.progress;
    questions = TRL_QUESTIONS;
    definitions = TRL_QUESTIONS[currentActive]?.definitions;
  } else if (isHRL) {
    progress = HRL_QUESTIONS[currentActive]?.progress;
    questions = HRL_QUESTIONS;
    definitions = HRL_QUESTIONS[currentActive]?.definitions;
  } else {
    progress = MRL_QUESTIONS[currentActive]?.progress;
    questions = MRL_QUESTIONS;
    definitions = MRL_QUESTIONS[currentActive]?.definitions;
  }

  return (
    <section className="questions" data-testid="questions">
      <Header
        title="Are you sure you want to go back to the dashboard?"
        content="If you go back to the dashboard, all of the progress you have made in the current assessment will be lost."
        displayRestartBttn={displayRestartBttn}
        onRestart={onRestart}
      />
      <ProgressBar percent={progress} />
      <section className="questions__content">
        <Answers questions={questions} isHRL={isHRL} />
        <Definition definitions={definitions} />
      </section>
      {isATRLQuestion && showExamples && (
        <section>
          <ExampleContent includeImages />
        </section>
      )}
    </section>
  );
};

export default Questions;
