// TRL PRODUCTION QUESTIONS
export const PRODUCTION_FULL_OPERATIONAL_TESTING = 'productionFullOperationalTesting';
export const PRODUCTION_DEFICIENCIES_ADDRESSED = 'productionDeficienciesAddressed';
export const PRODUCTION_COMPONENTS_INTEGRATED = 'productionComponentsIntegrated';
export const PRODUCTION_ENGINEERING_SUPPORT = 'productionEngineeringSupport';

// TRL PROTOTYPE QUESTIONS
export const PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING = 'prototypeCompleteSimulatedOperationalTesting';
export const PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED = 'prototypeCompleteDeficienciesAddressed';
export const PROTOTYPE_COMPLETE_INTEGRATED = 'prototypeCompleteIntegrated';
export const PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING = 'prototypeComponentsSimulatedOperationalTesting';
export const PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED = 'prototypeComponentsDeficienciesAddressed';
export const PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB = 'prototypeComponentsLowerControlledLab';
export const PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED = 'prototypeComponentsLowerDeficienciesAddressed';
export const PROTOTYPE_DESIGNS_CONTROLLED_LAB = 'prototypeDesignsControlledLab';
export const PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED = 'prototypeDesignsDeficienciesAddressed';

// TRL INITIAL QUESTIONS
export const EXPERIMENTAL_STUDIES = 'experimentalStudies';
export const CONFIRMED_HYPOTHESES = 'confirmedHypotheses';
export const TESTING_AND_VERIFICATION = 'testingAndVerification';

// MRL INITIAL QUESTIONS
export const IS_MANUFACTURING_NEEDED = 'isManufacturingNeeded';
export const MATERIALS_AND_PROCESSES_IDENTIFIED = 'materialsAndProcessesIdentified';

// MRL PRODUCTION QUESTIONS
export const PRODUCTION_FULL_RATE = 'productionFullRate';
export const PRODUCTION_EFFICIENCY_STANDARDS = 'productionEfficiencyStandards';
export const PRODUCTION_FUNDING = 'productionFunding';
export const PRODUCTION_LOW_RATE = 'productionLowRate';
export const MANUFACTURING_REQUIREMENTS = 'manufacturingRequirements';

// MRL PROTOTYPE QUESTIONS
export const PROTOTYPE_MANUFACTURING_CAPABILITIES = 'prototypeManufacturingCapabilities';
export const PROTOTYPE_FACILITIES_SUFFICIENT = 'prototypeFacilitiesSufficient';
export const PROTOTYPE_PROCESSING_DEMONSTRATED = 'prototypeProcessingDemonstrated';
export const PROTOTYPE_RISKS_PLAN = 'prototypeRisksPlan';
export const PROTOTYPE_COSTS = 'prototypeCosts';
export const PROTOTYPE_SPECIAL_FACILITIES = 'prototypeSpecialFacilities';

// HRL QUESTIONS
export const HRL_9 = 'hrl9';
export const HRL_8 = 'hrl8';
export const HRL_7 = 'hrl7';
export const HRL_6 = 'hrl6';
export const HRL_5 = 'hrl5';
export const HRL_4 = 'hrl4';
export const HRL_3 = 'hrl3';
export const HRL_2 = 'hrl2';

// ANSWER KEYS
export const INPUT_YES = 'yes';
export const INPUT_NO = 'no';

// MRL ANSWER KEYS
export const FIND_MRL_START = 'findMrlStart';

export interface DefinitionProps {
  highlight: string;
  definition?: string;
  bullets?: Array<string>;
}

export interface AnswerKey {
  content: string;
  extraInfo?: string;
  inputRequired: boolean;
  nextQuestion?: string;
  summaryStep?: string;
  trlScore?: number;
  mrlScore?: number;
  hrlScore?: number;
}

export interface Answer {
  [key: string]: AnswerKey;
}

export interface CurrentQuestion {
  content: string;
  highlight?: string;
  definitions?: DefinitionProps[];
  revealSumStepIfNotSelected?: boolean;
  answers: Answer;
  progress: number;
}

export interface Questions {
  [key: string]: CurrentQuestion;
}

export const TRL_QUESTIONS: Questions = {
  [PRODUCTION_FULL_OPERATIONAL_TESTING]: {
    content: 'Has the technology been used or tested under full operational conditions?',
    highlight: 'Full operational conditions',
    definitions: [
      {
        highlight: 'Full operational conditions',
        definition:
          "Full operational conditions means the technology has been used in an operational domain by people experienced in that domain under fully stressed conditions (e.g. representative sample of situations with representative levels of workload, demand, or complexity). For example this would reflect the first of Wright Brothers' planes that were bought and employed in operational use by the Army or the US mail services. For software, this indicates that the software has been used by clients in an operational work domain.",
      },
    ],
    progress: 0,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PRODUCTION_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Test technology to be used or tested under full operational conditions.',
      },
    },
  },
  [PRODUCTION_DEFICIENCIES_ADDRESSED]: {
    content: 'Have any deficiencies/vulnerabilities discovered during full operational conditions been addressed?',
    definitions: [
      {
        highlight: 'Deficiencies/vulnerabilities',
        definition:
          'Deficiencies and vulnerabilities refer to any failures or risks to fully satisfying intended operational performance requirements. For example, this might include vulnerabilities to environmental conditions such as heat or humidity. For software, this might include threats to security or incompatibility with common platforms or other systems.',
      },
    ],
    progress: 4,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_COMPONENTS_INTEGRATED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Address any deficiencies/vulnerabilities discovered during full operational conditions testing.',
      },
    },
  },
  [PRODUCTION_COMPONENTS_INTEGRATED]: {
    content: 'Have all components been integrated with existing hardware/software systems?',
    progress: 8,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_ENGINEERING_SUPPORT,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        trlScore: 8,
        summaryStep: 'Integrate all components with existing hardware/software systems.',
      },
    },
  },
  [PRODUCTION_ENGINEERING_SUPPORT]: {
    content:
      'Have all user, training, and maintenance  requirements been completed, including engineering support (if required)?',
    progress: 13,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 9,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        trlScore: 8,
        summaryStep: 'Complete all user, training, and maintenance requirements, including engineering support.',
      },
    },
  },
  [PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING]: {
    content: 'Has a complete product been tested in a simulated operational (high fidelity) environment?',
    highlight: 'High fidelity simulated operational environment',
    definitions: [
      {
        highlight: 'Complete product',
        definition:
          'A complete product is a fully functioning technology that is ready to be tested under operational conditions. For software, this would mean that the system is at least at its initial operational capability or that it is available for limited public use.',
      },
      {
        highlight: 'High fidelity simulated operational environment',
        definition:
          'A  simulated operational environment creates conditions that are representative of the demands that are faced in the intended operational domain. Has the technology been field tested in a high fidelity simulation? For example, the Army established performance  criteria that the Wright Brothers aircraft would have to meet to demonstrate potential operational effectiveness. For software, this indicates that end users have tested the software (e.g., a Beta version) to do work in their domain, and have had an opportunity to identify any bugs or potential limitations.',
      },
    ],
    progress: 13,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Test complete product in a simulated operational (high fidelity) environment.',
      },
    },
  },
  [PROTOTYPE_COMPLETE_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have any deficiencies/vulnerabilities discovered during simulated operational (high fidelity) environment testing been addressed?',
    definitions: [
      {
        highlight: 'Deficiencies/vulnerabilities',
        definition:
          'Results from testing at this stage should be documented and plans should be made to address any problems  that might impact the viability of a final technology or that may create risks for operational effectiveness.',
      },
    ],
    progress: 19,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPLETE_INTEGRATED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
        summaryStep:
          'Address any deficiencies/vulnerabilities discovered during simulated operational (high fidelity) environment testing.',
      },
    },
  },
  [PROTOTYPE_COMPLETE_INTEGRATED]: {
    content: 'Have all prototype components been well integrated with existing hardware/software systems?',
    progress: 25,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 7,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
        summaryStep: 'Integrate all prototype components with existing hardware/software systems.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING]: {
    content:
      'Have components of a complete product (or partial prototype) been tested in a simulated operational (medium fidelity) environment?',
    highlight: 'Medium fidelity simulated operational environment',
    definitions: [
      {
        highlight: 'Medium fidelity simulated operational environment',
        definition:
          'A simulated operational environment creates conditions that are somewhat representative of the demands that are faced in the intended operational domain. However, the conditions may differ somewhat from operational conditions to reduce stressors, mitigate risks, and to allow performance measurement. For example, the Wright Brothers first flight at Kitty Hawk demonstrated the viability of their design for an aircraft under preferential conditions that were specifically chosen to facilitate take-off and to reduce risks. For software, this indicates that the software or components of the software have been tested using  a representative population from the domain of application under conditions that allow concrete feedback about functionality to be collected (e.g., usability testing).',
      },
    ],
    progress: 31,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
        summaryStep:
          'Test components of a complete product (or partial prototype) in a simulated operational (medium fidelity) environment.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have expectations about the ultimate functionality of the technology been confirmed in the medium fidelity environment? ',
    definitions: [
      {
        highlight: 'Technology ultimate functionality',
        definition:
          'Results from the controlled laboratory conditions testing at this stage should be assessed against the results from prior laboratory experiments. Have the hypotheses been validated in conditions that are more representative of operational demands.',
      },
    ],
    progress: 38,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 6,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
        summaryStep:
          'Confirm expectations about the ultimate functionality of the technology in the medium fidelity environment.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB]: {
    content:
      'Have components of a complete product (or prototype) been tested under controlled laboratory conditions (low fidelity) that have potential relevance for operational effectiveness?',
    highlight: 'Representative laboratory conditions',
    definitions: [
      {
        highlight: 'Representative laboratory conditions',
        definition:
          'Representative laboratory conditions are conditions that have are chosen specifically because they allow functionally relevant aspects of the technology to be evaluated. For example, the Wright Brothers tested there wing-warping control system using kites and gliders. For software, this indicates that clients or representatives from the target domain have been able to interact with components of the system to verify that it is potentially useful for their work.',
      },
    ],
    progress: 38,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_DESIGNS_CONTROLLED_LAB,
        summaryStep: 'Test components of a complete product (or prototype) in controlled laboratory conditions.',
      },
    },
  },
  [PROTOTYPE_COMPONENTS_LOWER_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have expectations about the ultimate functionality of the technology been confirmed under conditions more representative of the ultimate operational environment?',
    definitions: [
      {
        highlight: 'Technology ultimate functionality',
        definition:
          'Results from the controlled laboratory conditions testing at this stage should be assessed against the results from prior laboratory experiments. Have the hypotheses been validated in conditions that are more representative of operational demands.',
      },
    ],
    progress: 44,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 5,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_DESIGNS_CONTROLLED_LAB,
        summaryStep:
          'Address any deficiencies/vulnerabilities discovered during controlled laboratory conditions testing.',
      },
    },
  },
  [PROTOTYPE_DESIGNS_CONTROLLED_LAB]: {
    content: 'Have design concepts or hypotheses been evaluated in a controlled laboratory environment?',
    highlight: 'Controlled laboratory conditions',
    definitions: [
      {
        highlight: 'Controlled laboratory conditions',
        definition:
          'Controlled laboratory conditions are conditions designed to test foundational hypotheses underlying the technology. For example, the Wright Brothers tested air foils using a wind tunnel in order to determine the lift coefficients for determining wing size and shape. For software, this indicates shallow testing with direct clients to evaluate the potential functionality of components of the system.',
      },
    ],
    progress: 56,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: EXPERIMENTAL_STUDIES,
        summaryStep: 'Test design concepts or hypotheses in a controlled laboratory environment.',
      },
    },
  },
  [PROTOTYPE_DESIGNS_DEFICIENCIES_ADDRESSED]: {
    content:
      'Have expectations about the ultimate functionality of the technology been confirmed under controlled laboratory conditions?',
    definitions: [
      {
        highlight: 'Technology ultimate functionality',
        definition:
          'Results from the controlled laboratory environment testing at this stage should be assessed against the the hypotheses and analytical models. Are the results from the laboratory conditions consistent with expectations?',
      },
    ],
    progress: 63,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 4,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: EXPERIMENTAL_STUDIES,
        summaryStep:
          'Address any deficiencies/vulnerabilities discovered during controlled laboratory environment testing.',
      },
    },
  },
  [EXPERIMENTAL_STUDIES]: {
    content:
      "Have you done any analytical/experimental studies to validate the predictions made for the solution's key parameters?",
    highlight: 'Analytical or experimental studies',
    definitions: [
      {
        highlight: 'Analytical or experimental studies',
        definition:
          'Is there an empirical foundation based on the scientific literature or analytical models to justify confidence in the feasibility and potential practical value of your technology? For example, the Wrights built on the foundation established by prior researchers to begin their wind tunnel studies to determine the lift coefficients. For software this may involve initial user studies to identify the potential value of an application or initial logic, equations or visualizations to demonstrate the viability of a software solution.',
      },
    ],
    progress: 63,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        nextQuestion: CONFIRMED_HYPOTHESES,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: TESTING_AND_VERIFICATION,
        summaryStep:
          "Complete all analytical/experimental studies to physically validate the predictions made for the solution's key parameters.",
      },
    },
  },
  [CONFIRMED_HYPOTHESES]: {
    content: 'Have your hypotheses been confirmed?',
    progress: 69,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        trlScore: 3,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: TESTING_AND_VERIFICATION,
        summaryStep: 'Confirm your hypotheses.',
      },
    },
  },
  [TESTING_AND_VERIFICATION]: {
    content:
      'Have design concepts or hypotheses been clearly specified with specific plans for testing and verification?',
    highlight: 'Design concepts and hypotheses',
    definitions: [
      {
        highlight: 'Design concepts and hypotheses',
        definition:
          ' Has the functionality of the technology been described sufficiently to design initial empirical tests or to elicit either theoretical or practical feedback from knowledgable domain experts?',
      },
    ],
    progress: 75,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        trlScore: 2,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        trlScore: 1,
        summaryStep: 'Specify design concepts or hypotheses testing and verification plans.',
      },
    },
  },
};

export const MRL_QUESTIONS: Questions = {
  [IS_MANUFACTURING_NEEDED]: {
    content: 'Is manufacturing required?',
    progress: 50,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: FIND_MRL_START,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 0,
      },
    },
  },
  [PRODUCTION_FULL_RATE]: {
    content: 'Is the technology being produced at a full rate capable of meeting operational demand?',
    progress: 17,
    definitions: [
      {
        highlight: 'Full rate production',
        definition:
          'Engineering/design changes are few and generally limited to continuous improvement changes or obsolescence issues. System, components, and items meet all engineering, performance, quality, and reliability requirements. Manufacturing process capability is at the appropriate quality level. All materials, tooling, inspection and test equipment, facilities and manpower are in place. Rate production unit costs meet goals, and funding is sufficient for production at required rates. Continuous process improvements are ongoing.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_EFFICIENCY_STANDARDS,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PRODUCTION_LOW_RATE,
        summaryStep: 'Produce Technology at a full rate, meeting operational demand.',
      },
    },
  },
  [PRODUCTION_EFFICIENCY_STANDARDS]: {
    content: 'Has the production process been fully engineered to satisfy efficiency and quality standards?',
    progress: 71,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PRODUCTION_FUNDING,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 9,
        summaryStep: 'Satisfy efficiency and quality standards through the production process.',
      },
    },
  },
  [PRODUCTION_FUNDING]: {
    content: 'Is funding sufficient for production at required rates?',
    progress: 86,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 10,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 9,
        summaryStep: 'Find sufficient funding for production at required rates.',
      },
    },
  },
  [PRODUCTION_LOW_RATE]: {
    content: 'Are pilot line capabilities demonstrated ready to begin low rate production?',
    progress: 42,
    definitions: [
      {
        highlight: 'Pilot line capability',
        definition:
          'All materials, manpower, tooling, test equipment, and facilities are proven on the pilot line and are available to meet the planned low-rate production schedule. Manufacturing and quality processes and procedures have been proven on a pilot line and are under control and ready for low-rate production. Known producibility risks and issues pose no significant challenges for low-rate production. Cost model and yield and rate analyses have been updated with pilot line results. Supplier qualification testing and first article inspections have been completed.',
      },
      {
        highlight: 'Low rate production',
        definition:
          'All systems engineering/design requirements should have been met such that there are minimal system changes. Major system design features are stable and have been proven in operational test and evaluation. Materials, parts, manpower, tooling, test equipment, and facilities are available to meet planned rate production schedules. The process is being monitored and learning curves are being analyzed with actual data in order to plan for Full Rate Production.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 8,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_MANUFACTURING_CAPABILITIES,
        summaryStep: 'Manufacturing processes need to be in place for low rate productions.',
      },
    },
  },
  [PROTOTYPE_MANUFACTURING_CAPABILITIES]: {
    content:
      'Have the needed manufacturing capabilities been empirically demonstrated in a production representative environment?',
    progress: 38,
    definitions: [
      {
        highlight: 'Production representative environment',
        definition:
          'Detailed producibility trade studies are completed and producibility enhancements and risk assessments are underway. The cost model has been updated with detailed designs produced, rolled up to system level, and tracked against allocated targets. Unit cost reduction efforts have been prioritized and are underway. Yield and rate analyses have been updated with production representative data. The supply chain and supplier quality assurance have been assessed and long-lead procurement plans are in place. Manufacturing plans and quality targets have been developed. Production tooling and test equipment design and development efforts are initiated and validation plans for Special Test Equipment/ Special Inspection Equipment (STE/SIE) are complete.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PROTOTYPE_FACILITIES_SUFFICIENT,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_PROCESSING_DEMONSTRATED,
        summaryStep: 'Demonstrate Manufacturing capabilities in a production relevant environment.',
      },
    },
  },
  [PROTOTYPE_FACILITIES_SUFFICIENT]: {
    content: 'Are materials, parts, manpower, equipment, and facilities sufficient to begin low rate production?',
    progress: 46,
    definitions: [
      {
        highlight: 'Low rate production',
        definition:
          'All systems engineering/design requirements should have been met such that there are minimal system changes. Major system design features are stable and have been proven in operational test and evaluation. Materials, parts, manpower, tooling, test equipment, and facilities are available to meet planned rate production schedules. The process is being monitored and learning curves are being analyzed with actual data in order to plan for Full Rate Production.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 7,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_PROCESSING_DEMONSTRATED,
        summaryStep: 'Begin low rate production with sufficient materials, parts, manpower, equipment, and facilities.',
      },
    },
  },
  [PROTOTYPE_PROCESSING_DEMONSTRATED]: {
    content:
      'Have all prototype manufacturing processing, materials, tooling, and test equipment been demonstrated in a production relevant environment?',
    progress: 54,
    definitions: [
      {
        highlight: 'Production relevant environment',
        definition:
          'An initial manufacturing approach has been developed. The majority of manufacturing processes have been defined and characterized, but there are still significant engineering and/or design changes in the system itself. However, preliminary design has been completed and producibility assessments and trade studies of key technologies and components are complete. Manufacturing processes and manufacturing technology solutions, materials, tooling and test equipment, as well as personnel skills have been demonstrated on components, subsystems, and/or systems. Cost, yield, and rate analyses have been performed to assess how prototype data compare to target objectives, and the program has developed appropriate risk reduction strategies to achieve cost requirements. Producibility trade studies and producibility considerations have shaped system development plans. Long-lead and key supply chain elements have been identified.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 6,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_RISKS_PLAN,
        summaryStep: 'Demonstrate all prototype manufacturing processing, materials, tooling, and test equipment.',
      },
    },
  },
  [PROTOTYPE_RISKS_PLAN]: {
    content:
      'Is there a detailed plan for developing a manufacturing process that accounts for potential risks and includes plans for mitigation?',
    progress: 47,
    definitions: [
      {
        highlight: 'A yes to this question suggests that...',
        definition:
          'The industrial base assessment should have been initiated to identify potential manufacturing sources. A risk management plan has been initiated. Identification of enabling/critical technologies and components is complete. With release of product data required for prototype component manufacturing, evaluation of the design to determine Key Characteristics has been initiated. Prototype materials have been demonstrated on components, but many manufacturing processes and procedures are still in development. Manufacturing technology development efforts, as well as producibility assessments of key technologies and components have been initiated.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 5,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: PROTOTYPE_COSTS,
        summaryStep:
          'Develop a detailed plan for developing a manufacturing process that accounts for potential risks and includes plans for mitigation.',
      },
    },
  },
  [PROTOTYPE_COSTS]: {
    content: 'Is there a preliminary plan for manufacturing that considers cost, required investments?',
    progress: 73,
    definitions: [
      {
        highlight: 'A yes to this question suggests that...',
        definition:
          'Required investments, such as capital, manufacturing technology development, and risk mitigation have been identified. Process variables, manufacturing, materials, and special requirement cost drivers have been identified, and cost driver uncertainty has been quantified. Initial producibility assessments of preferred material solution have been completed. Initial Key Performance Parameters have been identified as well as any requirements for special tooling, special handling, manufacturing skill sets, and workforce requirements and availability of facilities.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        nextQuestion: PROTOTYPE_SPECIAL_FACILITIES,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: MATERIALS_AND_PROCESSES_IDENTIFIED,
        summaryStep: 'Create a preliminary plan for manufacturing that considers cost.',
      },
    },
  },
  [PROTOTYPE_SPECIAL_FACILITIES]: {
    content:
      'Is there any special tooling, facilities, material handling, or skills required that need to be planned for?',
    progress: 80,
    definitions: [
      {
        highlight: 'A yes to this question suggests that...',
        definition:
          'Required investments, such as capital, manufacturing technology development, and risk mitigation have been identified. Process variables, manufacturing, materials, and special requirement cost drivers have been identified, and cost driver uncertainty has been quantified. Initial producibility assessments of preferred material solution have been completed. Initial Key Performance Parameters have been identified as well as any requirements for special tooling, special handling, manufacturing skill sets, and workforce requirements and availability of facilities.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 4,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: MATERIALS_AND_PROCESSES_IDENTIFIED,
        summaryStep: 'Identify any special tooling, facilities, material handling, or skills required.',
      },
    },
  },
  [MATERIALS_AND_PROCESSES_IDENTIFIED]: {
    content: 'Have materials and/or processes been characterized for manufacturing and availability?',
    progress: 87,
    definitions: [
      {
        highlight: 'A yes to this question suggests that...',
        definition:
          'This level of criteria for assessing manufacturing begins the analysis and the evaluation of the producibility and manufacturability of the proposed system concepts through analytical modeling and simulations and/or laboratory experiments. System concepts comparative cost models, analyses, and budgets are identified. Manufacturing and quality requirements for proposed system concepts identified and analyzed, including initial quality risks and issues, facility capabilities and capacity, and initial materials planning. This level of readiness is typical of technologies in Applied Research and Advanced Development. Experimental hardware models have been developed in a laboratory environment that may possess limited functionality. ',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 3,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: MANUFACTURING_REQUIREMENTS,
        summaryStep: 'Characterize materials and/or processes for manufacturing and availability.',
      },
    },
  },
  [MANUFACTURING_REQUIREMENTS]: {
    content: 'Have the manufacturing requirements been defined?',
    progress: 93,
    definitions: [
      {
        highlight: 'Manufacturing requirements',
        definition:
          'This includes identification and broad-based studies that address analysis of material and process approaches, material effects and availability, potential supply chains, needed workforce skillsets, potential future investments, etc. Manufacturing and quality potential future requirements are identified and analyzed. An understanding of manufacturing feasibility and risk is emerging.',
      },
    ],
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: false,
        mrlScore: 2,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        mrlScore: 1,
        summaryStep: 'Define manufacturing requirements.',
      },
    },
  },
};

export const HRL_QUESTIONS: Questions = {
  [HRL_9]: {
    content:
      'Has the system been successfully used in operations across the operational envelope with systematic monitoring of human system performance by human factor professionals?',
    definitions: [
      {
        highlight:
          'The qualified system has been deemed satisfactory for fielding in the operational environment. Testing of human-system interaction and the support system has been conducted using:',
        bullets: [
          'Appropriate human performance metrics and all criteria have been met',
          'The full range of use scenarios across the operational envelope',
          'The full range of mission conditions',
          'Final system hardware and software',
          'Intended system users',
          'Final training systems',
          'Final user procedures',
        ],
      },
      {
        highlight:
          'Requirements for human use have been identified based on relevant human-centered design principles, guidelines and standards, and conformance with those requirements has been fully tested and verified with the final system hardware and software, including:',
        bullets: [
          'Identification of relevant human factors design requirements',
          'Analyses of requirements for the human cognitive, perceptual and physical performance of tasks',
          'Safety and risk analyses, including mitigation strategies',
          'Manpower, personnel and training requirements',
          'Maintenance and sustainment requirements',
          'Environmental contraints and impacts',
          'If applicable, requirements for relevant HSI domains (such as force protection and survivability or habitability)',
        ],
      },
      {
        highlight:
          'Human-system interaction designs and support systems have been developed for all user roles and tested to determine their:',
        bullets: [
          'Effectiveness for accommodating human capabilities, limitations and needs',
          'Ability to perform all tasks with acceptable safety, usability, user satisfaction, and workload',
          'Ability to make required decisions based on needed situation awareness',
          'Ability to accommodate the effects of environmental conditions on human performance',
          'Suitability of automation function allocations and ability of the human to interact with it successfully in both normal and abnormal conditions',
        ],
      },
      {
        highlight: 'A system to track and resolve human-systems performance issues in use, with:',
        bullets: [
          'Human systems performance data and lessons learned being collected',
          'Human performance issues, errors, and accidents systematically analyzed to identify enhancements',
          'Potential system changes and upgrades to address human performance problems being identified',
        ],
      },
      {
        highlight:
          'Qualified human-systems experts (i.e., human factors, safety, manpower, personnel and training, etc.) are involved in preforming system development tasks, including analyses, requirements, design, training and testing, and continued monitoring and fielding',
      },
    ],
    progress: 20,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 9,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_8,
      },
    },
  },
  [HRL_8]: {
    content:
      'Has the system design been fully tested, verified and approved in mission operations using completed system hardware and software and users who are representative of actual operators?',
    definitions: [
      {
        highlight: 'For this level the following requirements must be met:',
        bullets: [
          'The system design has been fully tested, verified and approved in mission operations using completed system hardware and software and users who are representative of actual operators',
          'Have HSI design principles, standards, and guidance been addressed in the development process?',
          'A range of user scenarios and tasks have been tested with the full system in operational conditions',
          'A system is in place to track and resolve potential human systems issues (e.g., human error, health & safety concerns) that arise during future operations?',
          'Specific metrics will be used to track human performance in the operational system.',
          'Procedures are in place to address issues associated with manpower, personnel, and training concerns relative to adequately for maintaining operational effectiveness.',
          'The system design is consistent with HSI principles, standards and guidance.',
          'A team is responsible for monitoring and documenting human performance issues.',
        ],
      },
    ],
    progress: 30,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 8,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_7,
      },
    },
  },
  [HRL_7]: {
    content:
      'Has the system hardware and software been tested to verify satisfactory human performance in operationally relevant conditions?',
    definitions: [
      {
        highlight: 'For this level the following requirements must be met:',
        bullets: [
          'System hardware and software has been tested to verify satisfactory human performance in operationally relevant conditions',
          'A range of user scenarios and tasks have been tested with the full system in operational relevant conditions',
          'A system is in place to track and resolve potential human systems issues (e.g., human error, health & safety concerns) that arise during future operations?',
          'Specific metrics that will be used to track human performance in the operational system.',
          'Procedures are in place to address issues associated with manpower, personnel, and training concerns relative to adequately maintaining operational effectiveness.',
          'The system design is consistent with HSI principles, standards and guidance.',
          'A team is responsible for monitoring and documenting human performance issues.',
        ],
      },
    ],
    progress: 40,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 7,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_6,
      },
    },
  },
  [HRL_6]: {
    content:
      'Has testing been done with high fidelity simulations of mission tasks and conditions to assess human performance?',
    definitions: [
      {
        highlight: 'For this level the following requirements must be met:',
        bullets: [
          'Testing has been done with high fidelity simulations of mission tasks and conditions to assess human performance.',
          'A range of user scenarios and tasks have been tested with the full system in high fidelity simulations.',
          'A system is in place to track and resolve potential human systems issues (e.g., human error, health & safety concerns) that arise during future operations?',
          'Specific metrics that will be used to track human performance in the operational system.',
          'Procedures are in place to address issues associated with manpower, personnel, and training concerns relative to adequately maintaining operational effectiveness.',
          'The system design is consistent with HSI principles, standards and guidance.',
          'A team is responsible for monitoring and documenting human performance issues.',
        ],
      },
    ],
    progress: 50,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 6,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_5,
      },
    },
  },
  [HRL_5]: {
    content: 'Has user testing been done with prototypes in mission relevant part-task simulations?',
    definitions: [
      {
        highlight: 'For this level the following requirements must be met:',
        bullets: [
          'User testing has been done with prototypes in mission relevant part-task simulations.',
          'A range of user scenarios and tasks have been tested with prototypes',
          'Procedures are in place to address issues associated with manpower, personnel, and training concerns relative to adequately for maintaining operational effectiveness.',
          'The system design is consistent with HSI principles, standards and guidance.',
          'A team is responsible for monitoring and documenting human performance issues.',
        ],
      },
    ],
    progress: 60,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 5,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_4,
      },
    },
  },
  [HRL_4]: {
    content:
      'Have modeling, part-task and/or trade studies been conducted to address potential human performance issues?',
    definitions: [
      {
        highlight: 'For this level the following requirements must be met:',
        bullets: [
          'Modeling, part-task and/or trade studies have been conducted to address potential human performance issues.',
          'The choices for models, part-task and/or trade studies are based on prior task or work analyses.',
          'Procedures are in place to address issues associated with manpower, personnel, and training concerns relative to adequately for maintaining operational effectiveness.',
          'The system design is consistent with HSI principles, standards and guidance.',
          'A team is responsible for monitoring and documenting human performance issues.',
        ],
      },
    ],
    progress: 70,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 4,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_3,
      },
    },
  },
  [HRL_3]: {
    content:
      'Are there plans in place to address issues associated with manpower, personnel, and training concerns relative to adequately maintaining operational effectiveness?',
    definitions: [
      {
        highlight: 'For this level the following requirements must be met:',
        bullets: [
          'Modeling, part-task and/or trade studies have been conducted to address potential human performance issues.',
          'The choices for models, part-task and/or trade studies are based on prior task or work analyses.',
          'Plans are in place to address issues associated with manpower, personnel, and training concerns relative to adequately for maintaining operational effectiveness',
          'The system design is consistent with HSI principles, standards and guidance.',
          'A team is responsible for monitoring and documenting human performance issues.',
        ],
      },
    ],
    progress: 80,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 3,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        nextQuestion: HRL_2,
      },
    },
  },
  [HRL_2]: {
    content:
      'Are human-centered requirements and HSI principles, standards and guidance being considered in the design and development of this technology?',
    definitions: [
      {
        highlight: 'Please address the following dimensions:',
        bullets: [
          'Human-centered requirements and HSI principles, standards and guidance is being considered in the design and development of this technology.',
          'Task or work analysis is being conducted to identify scenarios and/or tasks and the associated functions that this technology might impact.',
          'There is a plan to engage expertise in human performance in the design and development process.',
        ],
      },
    ],
    progress: 100,
    answers: {
      [INPUT_YES]: {
        content: 'Yes',
        inputRequired: true,
        hrlScore: 2,
      },
      [INPUT_NO]: {
        content: 'No',
        inputRequired: false,
        hrlScore: 1,
      },
    },
  },
};

export const TEST_QUESTIONS: string[] = [
  PRODUCTION_FULL_OPERATIONAL_TESTING,
  PROTOTYPE_COMPLETE_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_SIMULATED_OPERATIONAL_TESTING,
  PROTOTYPE_COMPONENTS_LOWER_CONTROLLED_LAB,
  PROTOTYPE_DESIGNS_CONTROLLED_LAB,
  EXPERIMENTAL_STUDIES,
  TESTING_AND_VERIFICATION,
];
