import { QuestionAnswer } from '../reducers/TrlMrlCalculator';

export const CHANGE_QUESTION = 'CHANGE_QUESTION';
export const ADD_TRL_SCORE = 'ADD_TRL_SCORE';
export const ADD_HRL_SCORE = 'ADD_HRL_SCORE';
export const ADD_ANSWER_CHANGE_TRL_QUESTION = 'ADD_ANSWER_CHANGE_TRL_QUESTION';
export const ADD_ANSWER_CHANGE_MRL_QUESTION = 'ADD_ANSWER_CHANGE_MRL_QUESTION';
export const ADD_ANSWER_CHANGE_HRL_QUESTION = 'ADD_ANSWER_CHANGE_HRL_QUESTION';
export const ADD_MRL_SCORE_CHANGE_PAGE = 'ADD_MRL_SCORE_CHANGE_PAGE';
export const ADD_HRL_SCORE_CHANGE_PAGE = 'ADD_HRL_SCORE_CHANGE_PAGE';
export const ADD_INPUT_TEXT = 'ADD_INPUT_TEXT';
export const RESTART = 'RESTART';
export const RESTART_ASSESSMENT = 'RESTART_ASSESSMENT';
export const UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION = 'UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION';
export const UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE = 'UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE';
export const UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE = 'UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE';
export const UPDATE_HRL_QUESTIONS_ANSWERED_AND_ADD_SCORE = 'UPDATE_HRL_QUESTIONS_ANSWERED_AND_ADD_SCORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const CHANGE_INFO = 'CHANGE_INFO';
export const UPDATE_LEVEL1_HRL_DATA = 'UPDATE_LEVEL1_HRL_DATA';
export const UPDATE_LEVEL2_HRL_DATA = 'UPDATE_LEVEL2_HRL_DATA';
export const UPDATE_LEVEL3_HRL_DATA = 'UPDATE_LEVEL3_HRL_DATA';
export const UPDATE_LEVEL4_HRL_DATA = 'UPDATE_LEVEL4_HRL_DATA';
export const UPDATE_LEVEL5_HRL_DATA = 'UPDATE_LEVEL5_HRL_DATA';
export const UPDATE_LEVEL6_HRL_DATA = 'UPDATE_LEVEL6_HRL_DATA';
export const UPDATE_LEVEL7_HRL_DATA = 'UPDATE_LEVEL7_HRL_DATA';
export const UPDATE_LEVEL1_HRL_SCORE = 'UPDATE_LEVEL1_HRL_SCORE';
export const UPDATE_LEVEL2_HRL_SCORE = 'UPDATE_LEVEL2_HRL_SCORE';
export const UPDATE_LEVEL3_HRL_SCORE = 'UPDATE_LEVEL3_HRL_SCORE';
export const UPDATE_LEVEL4_HRL_SCORE = 'UPDATE_LEVEL4_HRL_SCORE';
export const UPDATE_LEVEL5_HRL_SCORE = 'UPDATE_LEVEL5_HRL_SCORE';
export const UPDATE_LEVEL6_HRL_SCORE = 'UPDATE_LEVEL6_HRL_SCORE';
export const UPDATE_LEVEL7_HRL_SCORE = 'UPDATE_LEVEL7_HRL_SCORE';

export interface AnswerNextQuestion {
  answer: string;
  nextQuestion: string;
}

export interface AddTrlScore {
  trlScore: number;
  nextQuestion: string;
  nextSteps: string[];
}

export interface AddMrlScore {
  mrlScore: number;
  nextPage: string;
  nextSteps: string[];
}

export interface AddHrlScore {
  hrlScore: number;
  nextPage: string;
  nextSteps: string[];
}

export interface ChangeAnswersAndQuestion {
  type: string;
  answers: any;
  question: string;
}
export interface EnvironmentInputProps {
  title: string | undefined;
  inputText: string;
}
export interface UpdateAnswersAddScore {
  answers: any;
  score: number;
  nextQuestion?: string;
  nextPage?: string;
  nextSteps: string[];
  environmentInputs?: EnvironmentInputProps[];
}

export interface StoreDataProps {
  trlScore?: number;
  trlDefinition?: string;
  mrlScore?: number;
  mrlDefinition?: string;
  hrlScore?: number;
  hrlDefinition?: string;
  dateCompleted?: Date | null;
  currentActive?: string;
  trlQuestionsAnswered?: QuestionAnswer;
  mrlQuestionsAnswered?: QuestionAnswer;
  hrlQuestionsAnswered?: QuestionAnswer;
  trlNextSteps?: Array<string>;
  mrlNextSteps?: Array<string>;
  hrlNextSteps?: Array<string>;
}

export const changeQuestion = (newQuestion: string): any => ({
  type: CHANGE_QUESTION,
  payload: newQuestion,
});

export const addMrlScoreAndChangePage = (payload: AddMrlScore): any => ({
  type: ADD_MRL_SCORE_CHANGE_PAGE,
  payload,
});

export const addHrlScoreAndChangePage = (payload: AddHrlScore): any => ({
  type: ADD_HRL_SCORE_CHANGE_PAGE,
  payload,
});

export const addAnswerAndChangeTrlQuestion = (payload: AnswerNextQuestion): any => ({
  type: ADD_ANSWER_CHANGE_TRL_QUESTION,
  payload,
});

export const addAnswerAndChangeMRLQuestion = (payload: AnswerNextQuestion): any => ({
  type: ADD_ANSWER_CHANGE_MRL_QUESTION,
  payload,
});

export const addAnswerAndChangeHRLQuestion = (payload: AnswerNextQuestion): any => ({
  type: ADD_ANSWER_CHANGE_HRL_QUESTION,
  payload,
});

export const restartAll = (): object => ({
  type: RESTART,
});

export const restartAssessment = (assessmentType: string): object => ({
  type: RESTART_ASSESSMENT,
  payload: assessmentType,
});

export const updateTrlQuestionsAnsweredAndAddScore = (payload: UpdateAnswersAddScore): any => ({
  type: UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  payload,
});

export const updateMrlQuestionsAnsweredAndAddScore = (payload: UpdateAnswersAddScore): any => ({
  type: UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  payload,
});

export const updateHrlQuestionsAnsweredAndAddScore = (payload: UpdateAnswersAddScore): any => ({
  type: UPDATE_HRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  payload,
});

export const updateQuestionsAnswered = (payload: ChangeAnswersAndQuestion): any => ({
  type: UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION,
  payload,
});

export const updateLevel1HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL1_HRL_DATA,
  payload,
});

export const updateLevel1HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL1_HRL_SCORE,
  payload,
});

export const updateLevel2HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL2_HRL_DATA,
  payload,
});

export const updateLevel2HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL2_HRL_SCORE,
  payload,
});

export const updateLevel3HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL3_HRL_DATA,
  payload,
});

export const updateLevel3HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL3_HRL_SCORE,
  payload,
});

export const updateLevel4HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL4_HRL_DATA,
  payload,
});

export const updateLevel4HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL4_HRL_SCORE,
  payload,
});

export const updateLevel5HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL5_HRL_DATA,
  payload,
});

export const updateLevel5HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL5_HRL_SCORE,
  payload,
});

export const updateLevel6HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL6_HRL_DATA,
  payload,
});

export const updateLevel6HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL6_HRL_SCORE,
  payload,
});

export const updateLevel7HrlData = (payload: any): any => ({
  type: UPDATE_LEVEL7_HRL_DATA,
  payload,
});

export const updateLevel7HrlScore = (payload: any): any => ({
  type: UPDATE_LEVEL7_HRL_SCORE,
  payload,
});

export const updateStore = (payload: StoreDataProps): any => ({
  type: UPDATE_STORE,
  payload,
});

export const changeInfo = (payload: any): any => ({
  type: CHANGE_INFO,
  payload,
});
