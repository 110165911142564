import React, { ReactElement } from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import Redux from './Redux';

interface Props {
  children: ReactElement;
  theme?: Theme;
}

const Providers = ({ children, theme }: Props): ReactElement => {
  if (theme) {
    return (
      <ThemeProvider theme={theme}>
        <Redux>{children}</Redux>
      </ThemeProvider>
    );
  }
  return <Redux>{children}</Redux>;
};

Providers.defaultProps = {
  theme: undefined,
};

export default Providers;
