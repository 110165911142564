import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../store/reducers';
// Components
import ScoreRing from '../../components/ring/ScoreRing';
import AnswersList from '../../components/answers/AnswersList';
import Legend from '../../components/legend/Legend';
import EnvBox from '../../components/envBox/EnvBox';
import { SectionsProps } from '../results/Results';
// Helpers
import { ANSWERS_OVERVIEW, TABLE_WIDTH, useWindowSize } from '../../utils/helper';
import { TRL_QUESTIONS, MRL_QUESTIONS } from '../../data/questions';
// Styles
import '../results/Results.scss';
import '../../components/answers/Answers.scss';

const AnswersOverviewSection = ({ environmentInputs }: SectionsProps): ReactElement => {
  const { trlScore, mrlScore, trlDefinition, mrlDefinition, trlQuestionsAnswered, mrlQuestionsAnswered } = useSelector(
    (state: Store) => state.TrlMrlCalculator
  );
  const [width] = useWindowSize();
  const isTabletOrDesktop = width >= TABLE_WIDTH;
  const layout = isTabletOrDesktop ? 'horizontal' : 'vertical';

  let answersListTrl: any | null = null;
  let answersListMrl: any | null = null;
  if (trlScore > 0)
    answersListTrl = (
      <AnswersList type="TRL" questionsAnswered={trlQuestionsAnswered} questionsReference={TRL_QUESTIONS} />
    );
  if (mrlScore > 0)
    answersListMrl = (
      <AnswersList type="MRL" questionsAnswered={mrlQuestionsAnswered} questionsReference={MRL_QUESTIONS} />
    );

  const scoreRingTrl = <ScoreRing score={trlScore} type="TRL" definition={trlDefinition} layout={layout} />;
  const scoreRingMrl = <ScoreRing score={mrlScore} type="MRL" definition={mrlDefinition} layout={layout} />;

  const title = (
    <section className="AnswersTitle">
      <h3>{ANSWERS_OVERVIEW}</h3>
      <Legend />
    </section>
  );

  const displayOnlyOne = trlScore > 0 && mrlScore === 0;

  if (isTabletOrDesktop)
    return (
      <section className="AnswersOverviewSec" data-testid="answers-overview">
        <section>
          {title}
          <section className="AnswersOverviewSec__overview">
            {trlScore > 0 && (
              <section className={`AnswersOverviewSec__overview__sec ${displayOnlyOne ? 'onlyOneOverview' : ''}`}>
                {scoreRingTrl}
                {answersListTrl}
              </section>
            )}
            {mrlScore > 0 && (
              <section className="AnswersOverviewSec__overview__sec">
                {scoreRingMrl}
                {answersListMrl}
              </section>
            )}
          </section>
        </section>
        <section className={`AnswersOverviewSec__envBoxes ${!displayOnlyOne ? 'onlyOneOverview' : ''}`}>
          <EnvBox environmentInputs={environmentInputs} />
        </section>
      </section>
    );
  return (
    <section className="AnswersOverviewSec" data-testid="answers-overview">
      {title}
      <section className="AnswersOverviewSec__rings">
        {scoreRingTrl}
        {answersListTrl}
        {scoreRingMrl}
        {answersListMrl}
      </section>
      <EnvBox environmentInputs={environmentInputs} />
    </section>
  );
};

export default AnswersOverviewSection;
