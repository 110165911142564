import React, { ReactElement } from 'react';
// import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from '../../store/reducers';
// Components
import { SharedCalculatorProps, SharedResultsProps } from '../../TrlMrlCalcInterface';
import Restart, { RestartProps } from '../restart/Restart';
import PageTitle from '../pageTitle/PageTitle';
import { SectionsNav } from '../sections/SectionsNav';
import { changePage } from '../../store/actions/Navigation';
// Helper
import { RESULTS, QUESTIONS, WELCOME, RESULTS_PREVIEW, HRL_TYPE, INDEPTH_HRL, RAPID_HRL } from '../../utils/helper';
// Styles
import './Header.scss';
// import PrintPdf from '../PDF/PrintPdf';

interface HeaderProps extends RestartProps, SharedCalculatorProps, SharedResultsProps {}

const Header = ({
  title,
  content,
  answersOverviewSec,
  nextPageSec,
  displayRestartBttn,
  homepageText,
  onRestart,
}: HeaderProps): ReactElement => {
  const { currentPage } = useSelector((state: Store) => state.Navigation);
  const dispatch = useDispatch();

  const goToDashboard = (): any => dispatch(changePage(WELCOME));
  let headerStyling = 'Header';
  if (currentPage === WELCOME) headerStyling = homepageText ? 'Header-welcome-homepageText' : 'Header-welcome';

  return (
    <section className={`${headerStyling}`}>
      {currentPage === RESULTS ? (
        <SectionsNav answersOverviewSec={answersOverviewSec} nextPageSec={nextPageSec} />
      ) : (
        <PageTitle currentPage={currentPage} homepageText={homepageText} />
      )}
      {currentPage === QUESTIONS && displayRestartBttn && <Restart title={title} content={content} />}
      {currentPage === RESULTS && (
        <div className="Header__Buttons">
          {displayRestartBttn && <Restart title={title} content={content} onRestart={goToDashboard} />}
        </div>
      )}
      {currentPage === RESULTS_PREVIEW && <Restart title={title} content={content} onRestart={goToDashboard} />}
      {/* {currentPage === WELCOME && (
        <Button variant="contained" onClick={nextButton} sx={{ marginLeft: 0 }}>
          Start Assessment
        </Button>
      )} */}
      {currentPage === HRL_TYPE && <Restart title={title} content={content} onRestart={onRestart} />}
      {currentPage === INDEPTH_HRL && <Restart title={title} content={content} onRestart={onRestart} />}
      {currentPage === RAPID_HRL && <Restart title={title} content={content} onRestart={onRestart} />}
    </section>
  );
};

export default Header;
