import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import { Store } from '../../store/reducers';
import IndepthHrlResultsDetails from './IndepthHrlResultsDetails';

const IndepthHrlResults = (): ReactElement => {
  const {
    level1HrlData,
    level1HrlScore,
    level2HrlData,
    level2HrlScore,
    level3HrlData,
    level3HrlScore,
    level4HrlData,
    level4HrlScore,
    level5HrlData,
    level5HrlScore,
    level6HrlData,
    level6HrlScore,
    level7HrlData,
    level7HrlScore,
  } = useSelector((state: Store) => state.TrlMrlCalculator);

  return (
    <>
      <Typography variant="h3">In-Depth Results Breakdown</Typography>
      <Typography>Your final HRL result is pulled from the lowest score you got across all dimensions</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginY: '0.5rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
          <CheckCircleIcon color="secondary" /> Completed Requirements
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
          <RotateLeftIcon /> Partially Completed
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
          <HighlightOffIcon /> Not Started
        </Box>
      </Box>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Human Use Requirements: HRL {level1HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level1HrlData} />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Testing: HRL {level2HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level2HrlData} />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Design: HRL {level3HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level3HrlData} />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Safety: HRL {level4HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level4HrlData} />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Tasks and Procedures: HRL {level5HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level5HrlData} />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Manpower, Personnel, and Training: HRL {level6HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level6HrlData} />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Other HSI Domains: HRL {level7HrlScore}</Typography>
        </AccordionSummary>
        <IndepthHrlResultsDetails answers={level7HrlData} />
      </Accordion>
    </>
  );
};

export default IndepthHrlResults;
