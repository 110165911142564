/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Button, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Header from '../../components/header/Header';
import { changePage } from '../../store/actions/Navigation';
import {
  updateLevel1HrlData,
  updateLevel2HrlData,
  updateLevel3HrlData,
  updateLevel4HrlData,
  updateLevel5HrlData,
  updateLevel6HrlData,
  updateLevel7HrlData,
  updateLevel1HrlScore,
  updateLevel2HrlScore,
  updateLevel3HrlScore,
  updateLevel4HrlScore,
  updateLevel5HrlScore,
  updateLevel6HrlScore,
  updateLevel7HrlScore,
} from '../../store/actions/TrlMrlCalculator';
import { RESULTS } from '../../utils/helper';
import HrlStepContent from './HrlStepContent';
import inDepthSteps from '../../data/InDepthSteps';

const InDepthAssessment = ({ displayRestartBttn, onRestart }): ReactElement => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const { handleSubmit, reset, control, formState } = useForm({
    mode: 'onChange',
  });
  const { isValid, errors } = formState;

  const steps = inDepthSteps.map(step => step.title);

  const onSubmit = (data: any): void => {
    const results = Number(Object.keys(data).find(key => data[key] !== 'completed'));
    let score;
    if (!Number.isNaN(results)) {
      score = results === 1 ? results : results - 1;
    } else {
      score = 9;
    }

    switch (activeStep) {
      case 0:
        dispatch(updateLevel1HrlData(data));
        dispatch(updateLevel1HrlScore(score));
        break;
      case 1:
        dispatch(updateLevel2HrlData(data));
        dispatch(updateLevel2HrlScore(score));

        break;
      case 2:
        dispatch(updateLevel3HrlData(data));
        dispatch(updateLevel3HrlScore(score));

        break;
      case 3:
        dispatch(updateLevel4HrlData(data));
        dispatch(updateLevel4HrlScore(score));

        break;
      case 4:
        dispatch(updateLevel5HrlData(data));
        dispatch(updateLevel5HrlScore(score));

        break;
      case 5:
        dispatch(updateLevel6HrlData(data));
        dispatch(updateLevel6HrlScore(score));
        break;
      case 6:
        dispatch(updateLevel7HrlData(data));
        dispatch(updateLevel7HrlScore(score));
        break;

      default:
        // eslint-disable-next-line no-console
        console.error("can't update hrl data");
        break;
    }

    if (activeStep === steps.length - 1) {
      dispatch(changePage(RESULTS));
      window.scrollTo(0, 0);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      window.scrollTo(0, 0);
    }
    reset();
  };

  // const handleBack = (): void => {
  //   setActiveStep(prevActiveStep => prevActiveStep - 1);
  // };

  return (
    <>
      <Header
        title="Are you sure you want to start a new assessment?"
        content="If you restart the assessment, all of the progress you have made will be lost."
        displayRestartBttn={displayRestartBttn}
        onRestart={onRestart}
      />
      <Box component="form" sx={{ padding: '1em 3em' }} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ marginBottom: '2em', maxWidth: '1000px' }}>
          Progress through the stepper. On each step, answer each card on if requirements have been completed, partially
          completed, or not started. Upload or type supporting evidence at the bottom of each page.
        </Typography>
        <Stepper alternativeLabel activeStep={activeStep} sx={{ marginY: '1em' }}>
          {steps.map(label => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel sx={{ '.MuiStepLabel-label.Mui-active': { fontWeight: 700 } }}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Typography variant="h3">{steps[activeStep]}</Typography> 
        <HrlStepContent activeStep={activeStep} control={control} errors={errors} />
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1em',
            marginY: '1em',
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>Click next when you have provided input to the above prompts</Typography>
          <Box>
            {/* <Button variant="outlined" disabled={activeStep === 0} onClick={handleBack} sx={{ marginRight: '0.5em' }}>
              Back
            </Button> */}
            <Button variant="contained" type="submit" disabled={!isValid}>
              Next
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default InDepthAssessment;
