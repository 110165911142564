import React, { ReactElement } from 'react';
import { DefinitionProps } from '../../data/questions';

interface DefProps {
  definitions: DefinitionProps[] | undefined;
}

const Definition = ({ definitions }: DefProps): ReactElement => {
  return (
    <section className="definition">
      {definitions?.map(({ highlight, definition, bullets }) => (
        <section key={highlight}>
          <h4>{highlight}</h4>
          {definition && <p>{definition}</p>}
          {bullets && (
            <ul>
              {bullets.map((bullet, i) => (
                <li key={i}>{bullet}</li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </section>
  );
};

export default Definition;
