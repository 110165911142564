/* eslint-disable prettier/prettier */
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { Store } from '../../store/reducers';
import { changePage } from '../../store/actions/Navigation';
// Components
import ScoreRing from '../../components/ring/ScoreRing';
import Levels from '../../components/levels/Levels';
import References from '../../components/references/References';
import IndepthHrlResults from '../hrl/IndepthHrlResults';
// Helpers
import { useWindowSize, TABLE_WIDTH, INDEPTH_HRL } from '../../utils/helper';
import RiskMatrix from '../../assets/risk-matrix.png';
// Styles
import '../results/Results.scss';

const ReadinessLevelsSection = ({ isPreview, isHRL }): ReactElement => {
  const dispatch = useDispatch();
  const {
    trlScore,
    hrlScore,
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore,
  } = useSelector((state: Store) => state.TrlMrlCalculator);
  const [width] = useWindowSize();
  const layout = width >= TABLE_WIDTH ? 'horizontal' : 'vertical';
  const indepthHrlScore = Math.min(
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore
  );

  return (
    <section className="ReadinessLevelsSec">
      <h3>{isPreview ? `Preview of ${isHRL ? 'HRL' : 'TRL'} Result` : 'Readiness Levels'}</h3>
      {/* {isPreview && <p>You can export these results at the end of the readiness assessment.</p>} */}
      <section className="ReadinessLevelsSec__levels">
        {!isHRL && trlScore > 0 && (
          <Box
            component="section"
            sx={{ width: isPreview ? '100%' : '50%' }}
            className="ReadinessLevelsSec__levels__sec extra-margin"
          >
            <ScoreRing
              score={trlScore}
              type="TRL"
              definition={`You recieved TRL ${trlScore}. The technology's TRL could be between ${
                trlScore === 1 ? trlScore : trlScore - 1
              }-${trlScore === 9 ? trlScore : trlScore + 1}`}
              layout={layout}
            />
            <Levels title="TRL" score={trlScore} />
            <References
              links={[
                {
                  title: 'GAO Technology Readiness Assessment (pg. 17)',
                  url: 'https://www.gao.gov/assets/680/679006.pdf',
                },
                {
                  title: 'NASA Technology Readiness Level Definitions',
                  url: 'https://www.nasa.gov/pdf/458490main_TRL_Definitions.pdf',
                },
              ]}
            />
          </Box>
        )}
        {hrlScore > 0 && (
          <Box
            component="section"
            sx={{ width: isPreview ? '100%' : '50%' }}
            className="ReadinessLevelsSec__levels__sec extra-margin"
          >
            <ScoreRing
              score={hrlScore}
              type="HRL"
              definition={`You recieved HRL ${hrlScore}. The technology's HRL could be between ${
                hrlScore === 1 ? hrlScore : hrlScore - 1
              }-${hrlScore === 9 ? hrlScore : hrlScore + 1}`}
              layout={layout}
            />
            {level7HrlScore === 0 && (
              <>
                <Typography sx={{ fontStyle: 'italic', marginTop: '1rem' }}>
                  HRL result above is pulled from the{' '}
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    rapid assessment.&nbsp;
                  </Typography>
                  Click the button below to take the{' '}
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    in-depth assessment.
                  </Typography>
                </Typography>
                <Button
                  variant="contained"
                  sx={{ marginY: '1rem' }}
                  onClick={(): void => dispatch(changePage(INDEPTH_HRL))}
                >
                  Start the in-depth HRL Assessment
                </Button>
              </>
            )}
            {hrlScore !== 0 && <Levels title="HRL" score={hrlScore} />}
            {/* <References
              links={[
                {
                  title: 'DoD Manufacturing Readiness Level (MRL) Deskbook (pg. 11-14)',
                  url: 'http://www.dodmrl.com/MRL%20Deskbook%20V2020.pdf',
                },
              ]}
            /> */}
          </Box>
        )}
        {level7HrlScore > 0 && (
          <Box
            component="section"
            sx={{ width: isPreview ? '100%' : '50%' }}
            className="ReadinessLevelsSec__levels__sec extra-margin"
          >
            <ScoreRing
              score={indepthHrlScore}
              type="HRL-indepth"
              definition={`You recieved HRL ${indepthHrlScore}. The technology's HRL could be between ${
                indepthHrlScore === 1 ? indepthHrlScore : indepthHrlScore - 1
              }-${indepthHrlScore === 9 ? indepthHrlScore : indepthHrlScore + 1}`}
              layout={layout}
            />
            <Levels title="HRL" score={indepthHrlScore} />
          </Box>
        )}
      </section>
      {level7HrlScore > 0 && (
        <Box component="section" sx={{ marginTop: '2rem' }}>
          <IndepthHrlResults />
        </Box>
      )}
      {(trlScore > 0 || hrlScore > 0) && (
        <Box component="section" sx={{ marginTop: '2rem' }}>
          <Typography variant="h3">
            Use your TRL and HRL scores to find your Risk Level using the matrix below
          </Typography>
          <Box
            component="img"
            src={RiskMatrix}
            sx={{ maxHeight: '500px', display: 'block', marginX: 'auto', marginY: '2rem' }}
          />
          <Typography sx={{ marginBottom: '1em' }}>
            The risk matrix Reflects the coupling of TRL and HRL. Five levels of risk associated with discrepancies
            between HRL and TRL ratings are defined:
          </Typography>
          <ul>
            <li>Empty: These combinations are not possible because it is precluded by the HRL definitions</li>
            <li>
              Low Risk: Low likelihood of inadequate human-system performance with minor impacts on Mission performance
            </li>
            <li>
              Moderate Risk: Moderate likelihood of inadequate human-system performance with moderate impacts on mission
              performance
            </li>
            <li>
              High Risk: High likelihood of inadequate human-system performance with major impacts on mission
              performance
            </li>
            <li>
              Very High Risk: Very high likelihood of inadequate human-system performance with severe or catastrophic
              impacts on mission performance
            </li>
          </ul>
          <Typography sx={{ marginBottom: '1em' }}>
            Ideally, design and development activities occur such that the TRL and HRL levels remain aligned. That is,
            the human systems work on a given program should be conducted in parallel with the technical work focused on
            maturing the technology. Misalignment between the TRL and HRL ratings may generate varying levels of program
            risk, depending on the phase of development and the extent of the discrepancy. To minimize program risks
            impacting cost, schedule, and performance as well as risk to the system’s human users of fielding a
            technology that is not ready for human use, progression through the HRL scale should closely mirror
            progression through the TRL scale, as shown by alignment between the TRL and HRL scales in The Risk Matrix.
            In practice, however, one of two situations can potentially occur: (1) the HRL rating lags the TRL rating,
            or (2) the TRL rating lags the HRL rating.
          </Typography>
          <Typography sx={{ marginBottom: '1em' }}>
            In the first situation, the HRL rating lags the TRL rating (below the diagonal). In this case, risk always
            increases whenever a discrepancy is present. Historically, when human systems work is neglected until the
            end of a program, human use requirements cannot be properly addressed because many technical constraints are
            in place that make required changes too expensive or leave insufficient time in the schedule for the
            necessary rework. To properly address human systems needs, these human use requirements must be built into
            the system design from the beginning and thoroughly tested throughout the development process.
          </Typography>
          <Typography sx={{ marginBottom: '1em' }}>
            In the second situation, the TRL rating may lag the HRL rating (above the diagonal). This occurrence does
            not necessarily represent program risk, particularly if there is good communication within the design
            team... In some cases, human systems work may safely precede technology development efforts. Generally,
            however, it will not be possible for HRL ratings to advance more than two levels ahead of technology
            development (See ANSI/HFES 400-2021, p. 6-9)
          </Typography>
        </Box>
      )}
    </section>
  );
};

export default ReadinessLevelsSection;
