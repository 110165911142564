import { combineReducers } from 'redux';
import TrlMrlCalculator, { State as TrlMrlCalculatorState } from './TrlMrlCalculator';
import Navigation, { State as NavigationState } from './Navigation';

export interface Store {
  TrlMrlCalculator: TrlMrlCalculatorState;
  Navigation: NavigationState;
}

export default combineReducers({ TrlMrlCalculator, Navigation });
