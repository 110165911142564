import React, { ReactElement } from 'react';
import CustomModal from './CustomModal';
import './CardInfoModal.scss';
import '../exampleContent/Card.scss';
import Card from '../exampleContent/Card';

export type Props = {
  isOpen: boolean;
  title: string;
  content?: string | JSX.Element;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm?: () => void;
  imagePath: string;
  subtitle: string;
};

export default ({ isOpen, onCancel, imagePath, title, subtitle }: Props): ReactElement => {
  return (
    <CustomModal isOpen={isOpen} onClose={onCancel} boxClass="Modal__box Modal__box-card">
      <div className="ModalTemplate">
        <Card
          imagePath={imagePath}
          className="card modal card__modal"
          onCancel={onCancel}
          title={title}
          subtitle={subtitle}
        />
      </div>
    </CustomModal>
  );
};
