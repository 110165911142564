import React, { ReactElement } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import CustomTextField from '../../components/shared/CustomTextField';
import inDepthSteps from '../../data/InDepthSteps';
import InDepthCardInputs from './InDepthCardInputs';

const HrlStepContent = ({ activeStep, control, errors }): ReactElement => {
  return (
    <>
      <Typography>{inDepthSteps[activeStep].description}</Typography>
      <Box sx={{ marginY: '1em' }}>
        {inDepthSteps[activeStep].sections.map((section, i) => (
          <div key={i}>
            <Typography variant="h4" sx={{ marginY: '1em' }}>
              {section.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: '0.5em', justifyContent: 'space-between' }}>
              {section.inputs.map((input, index) => (
                <InDepthCardInputs key={index} input={input} control={control} />
              ))}
            </Box>
            <Divider sx={{ marginTop: '2em', borderBottomWidth: 'medium' }} />
          </div>
        ))}
      </Box>
      <Typography variant="h4">Provide Evidence</Typography>
      <CustomTextField
        multiline
        control={control}
        errors={errors}
        defaultValue=""
        name={`${inDepthSteps[activeStep].title} input`}
        rows={5}
        label="Add files or text to document HRL related activities and results:"
        sx={{ label: { fontWeight: 700, fontSize: '0.8em' } }}
      />
      <Button
        variant="contained"
        component="label"
        startIcon={<Add />}
        sx={{ textTransform: 'none', marginTop: '1em', marginBottom: '2em' }}
      >
        Upload File(s)
        <input hidden multiple type="file" />
      </Button>
    </>
  );
};

export default HrlStepContent;
