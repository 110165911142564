/* eslint-disable prettier/prettier */
/* eslint-disable react/require-default-props */
import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
// Helper
import { TITLES } from '../../utils/helper';

interface PageTitleProps {
  currentPage: string;
  homepageText?: {
    title: string;
    text: string;
  };
}

const PageTitle = ({ currentPage, homepageText }: PageTitleProps): ReactElement => {
  let title = TITLES[currentPage]?.title;
  let definition = TITLES[currentPage]?.definition;
  if (currentPage === 'welcome' && homepageText) {
    title = homepageText.title;
    definition = homepageText.text;
  }
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h3" sx={{ mb: 2 }}>{title}</Typography>
      {definition && <Typography variant="body1">{definition}</Typography>}
    </Box>
  );
};

export default PageTitle;
