import React, { ReactElement } from 'react';
import './References.scss';

interface LinksProps {
  title: string;
  url: string;
}

interface ReferencesProps {
  links: LinksProps[];
}

const References = ({ links }: ReferencesProps): ReactElement => {
  return (
    <section className="References">
      <h6>References:</h6>
      {links.map(({ title, url }) => (
        <a href={url} rel="noopener noreferrer" target="_blank" key={title}>
          {title}
        </a>
      ))}
    </section>
  );
};

export default References;
