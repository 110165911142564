import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import Providers from './components/providers/Providers';
import Calculator from './Calculator';
import { SharedCalculatorProps } from './TrlMrlCalcInterface';

const TrlMrlCalculator = ({
  returnData,
  displayRestart,
  resultsAnswersOverview,
  resultsNextSteps,
  onRestart,
  displayPdf,
  storeData,
  displayInstructions,
  homepageText,
  returnPageName,
  theme,
}: SharedCalculatorProps): ReactElement => {
  const [answersOverviewSec, setAnswersOverview] = useState(true);
  const [nextPageSec, setNextPageSec] = useState(true);
  const [displayRestartBttn, setDisplayRestartBttn] = useState(false);
  const [displayPdfBttn, setDisplayPdfBttn] = useState(false);

  const returnUpdatedData = useCallback((value: any) => {
    if (returnData) returnData(value);
  }, []);

  useEffect(() => {
    if (resultsAnswersOverview !== undefined && !resultsAnswersOverview) setAnswersOverview(false);
    if (resultsNextSteps !== undefined && !resultsNextSteps) setNextPageSec(false);
  }, [resultsAnswersOverview, resultsNextSteps]);

  useEffect(() => {
    if (displayRestart) setDisplayRestartBttn(displayRestart);
  }, [displayRestart]);

  useEffect(() => {
    if (displayPdf) setDisplayPdfBttn(displayPdf);
  }, [displayPdf]);

  return (
    <Providers theme={theme}>
      <Calculator
        returnData={returnUpdatedData}
        displayRestartBttn={displayRestartBttn}
        displayPdfBttn={displayPdfBttn}
        answersOverviewSec={answersOverviewSec}
        nextPageSec={nextPageSec}
        onRestart={onRestart}
        storeData={storeData}
        displayInstructions={displayInstructions}
        homepageText={homepageText}
        returnPageName={returnPageName}
        theme={theme}
      />
    </Providers>
  );
};

export default TrlMrlCalculator;
