import {
  CHANGE_QUESTION,
  ADD_ANSWER_CHANGE_TRL_QUESTION,
  ADD_ANSWER_CHANGE_MRL_QUESTION,
  ADD_ANSWER_CHANGE_HRL_QUESTION,
  ADD_MRL_SCORE_CHANGE_PAGE,
  ADD_HRL_SCORE_CHANGE_PAGE,
  RESTART,
  RESTART_ASSESSMENT,
  UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION,
  UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  UPDATE_HRL_QUESTIONS_ANSWERED_AND_ADD_SCORE,
  UPDATE_STORE,
  CHANGE_INFO,
  EnvironmentInputProps,
  UPDATE_LEVEL1_HRL_DATA,
  UPDATE_LEVEL2_HRL_DATA,
  UPDATE_LEVEL3_HRL_DATA,
  UPDATE_LEVEL4_HRL_DATA,
  UPDATE_LEVEL5_HRL_DATA,
  UPDATE_LEVEL6_HRL_DATA,
  UPDATE_LEVEL7_HRL_DATA,
  UPDATE_LEVEL1_HRL_SCORE,
  UPDATE_LEVEL2_HRL_SCORE,
  UPDATE_LEVEL3_HRL_SCORE,
  UPDATE_LEVEL4_HRL_SCORE,
  UPDATE_LEVEL5_HRL_SCORE,
  UPDATE_LEVEL6_HRL_SCORE,
  UPDATE_LEVEL7_HRL_SCORE,
} from '../actions/TrlMrlCalculator';
import { Action } from '..';
import { TRL_NEXT_STEPS, MRL_NEXT_STEPS, HRL_NEXT_STEPS } from '../../data/nextSteps';
import { PRODUCTION_FULL_OPERATIONAL_TESTING } from '../../data/questions';

export interface AnswerKey {
  [key: string]: {
    inputText: string;
    questionNum: number;
  };
}

export interface QuestionAnswer {
  [key: string]: AnswerKey;
}

export interface State {
  trlScore: number;
  trlDefinition: string;
  mrlScore: number;
  mrlDefinition: string;
  hrlScore: number;
  hrlDefinition: string;
  dateCompleted: Date | null;
  currentActive: string;
  trlQuestionsAnswered: QuestionAnswer;
  mrlQuestionsAnswered: QuestionAnswer;
  hrlQuestionsAnswered: QuestionAnswer;
  trlNextSteps: Array<string>;
  mrlNextSteps: Array<string>;
  hrlNextSteps: Array<string>;
  firstName: string;
  lastName: string;
  companyName: string;
  technologyName: string;
  environmentInputs: EnvironmentInputProps[];
  level1HrlData: QuestionAnswer[];
  level2HrlData: QuestionAnswer[];
  level3HrlData: QuestionAnswer[];
  level4HrlData: QuestionAnswer[];
  level5HrlData: QuestionAnswer[];
  level6HrlData: QuestionAnswer[];
  level7HrlData: QuestionAnswer[];
  level1HrlScore: number;
  level2HrlScore: number;
  level3HrlScore: number;
  level4HrlScore: number;
  level5HrlScore: number;
  level6HrlScore: number;
  level7HrlScore: number;
}

const initialState: State = {
  environmentInputs: [],
  firstName: '',
  lastName: '',
  companyName: '',
  technologyName: '',
  trlScore: 0,
  trlDefinition: '',
  mrlScore: 0,
  mrlDefinition: '',
  hrlScore: 0,
  hrlDefinition: '',
  dateCompleted: null,
  currentActive: PRODUCTION_FULL_OPERATIONAL_TESTING,
  trlQuestionsAnswered: {},
  mrlQuestionsAnswered: {},
  hrlQuestionsAnswered: {},
  trlNextSteps: [],
  mrlNextSteps: [],
  hrlNextSteps: [],
  level1HrlData: [],
  level2HrlData: [],
  level3HrlData: [],
  level4HrlData: [],
  level5HrlData: [],
  level6HrlData: [],
  level7HrlData: [],
  level1HrlScore: 0,
  level2HrlScore: 0,
  level3HrlScore: 0,
  level4HrlScore: 0,
  level5HrlScore: 0,
  level6HrlScore: 0,
  level7HrlScore: 0,
};

export default (state = initialState, action: Action): State => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_QUESTION: {
      return { ...state, currentActive: payload };
    }
    case ADD_ANSWER_CHANGE_TRL_QUESTION: {
      return {
        ...state,
        trlQuestionsAnswered: payload.answer,
        currentActive: payload.nextQuestion,
      };
    }
    case ADD_ANSWER_CHANGE_MRL_QUESTION: {
      return {
        ...state,
        mrlQuestionsAnswered: payload.answer,
        currentActive: payload.nextQuestion,
      };
    }
    case ADD_ANSWER_CHANGE_HRL_QUESTION: {
      return {
        ...state,
        hrlQuestionsAnswered: payload.answer,
        currentActive: payload.nextQuestion,
      };
    }
    case ADD_MRL_SCORE_CHANGE_PAGE: {
      return {
        ...state,
        mrlScore: payload.mrlScore,
        mrlDefinition: MRL_NEXT_STEPS[payload.mrlScore].definition,
        dateCompleted: new Date(),
        mrlNextSteps: payload.nextSteps,
      };
    }
    case ADD_HRL_SCORE_CHANGE_PAGE: {
      return {
        ...state,
        hrlScore: payload.hrlScore,
        hrlDefinition: HRL_NEXT_STEPS[payload.hrlScore].definition,
        dateCompleted: new Date(),
        hrlNextSteps: payload.nextSteps,
        currentActive: '',
      };
    }
    case RESTART: {
      return {
        ...initialState,
        trlQuestionsAnswered: {},
        mrlQuestionsAnswered: {},
        hrlQuestionsAnswered: {},
        level1HrlData: [],
        level2HrlData: [],
        level3HrlData: [],
        level4HrlData: [],
        level5HrlData: [],
        level6HrlData: [],
        level7HrlData: [],
      };
    }
    case RESTART_ASSESSMENT: {
      switch (payload) {
        case 'TRL':
          return {
            ...state,
            trlQuestionsAnswered: {},
            trlScore: 0,
            trlDefinition: '',
            trlNextSteps: [],
          };
        case 'Rapid HRL':
          return {
            ...state,
            hrlQuestionsAnswered: {},
            hrlScore: 0,
            hrlDefinition: '',
            hrlNextSteps: [],
          };
        case 'In-depth HRL':
          return {
            ...state,
            level1HrlData: [],
            level2HrlData: [],
            level3HrlData: [],
            level4HrlData: [],
            level5HrlData: [],
            level6HrlData: [],
            level7HrlData: [],
            level1HrlScore: 0,
            level2HrlScore: 0,
            level3HrlScore: 0,
            level4HrlScore: 0,
            level5HrlScore: 0,
            level6HrlScore: 0,
            level7HrlScore: 0,
          };
        default:
          return state;
      }
    }
    case UPDATE_QUESTIONS_ANSWERED_AND_CHANGE_QUESTION: {
      let questionsAnswered;
      if (payload.type === 'TRL')
        questionsAnswered = { trlQuestionsAnswered: payload.answers, hrlQuestionsAnswered: {} };
      else questionsAnswered = { hrlQuestionsAnswered: payload.answers };
      return {
        ...state,
        ...questionsAnswered,
        currentActive: payload.question,
      };
    }
    case UPDATE_TRL_QUESTIONS_ANSWERED_AND_ADD_SCORE: {
      return {
        ...state,
        trlQuestionsAnswered: payload.answers,
        trlDefinition: TRL_NEXT_STEPS[payload.score].definition,
        trlScore: payload.score,
        currentActive: payload.nextQuestion,
        trlNextSteps: payload.nextSteps,
        environmentInputs: payload.environmentInputs,
      };
    }
    case UPDATE_MRL_QUESTIONS_ANSWERED_AND_ADD_SCORE: {
      return {
        ...state,
        mrlQuestionsAnswered: payload.answers,
        mrlScore: payload.score,
        mrlDefinition: MRL_NEXT_STEPS[payload.score].definition,
        mrlNextSteps: payload.nextSteps,
      };
    }
    case UPDATE_HRL_QUESTIONS_ANSWERED_AND_ADD_SCORE: {
      return {
        ...state,
        hrlQuestionsAnswered: payload.answers,
        hrlScore: payload.score,
        hrlDefinition: HRL_NEXT_STEPS[payload.score].definition,
        hrlNextSteps: payload.nextSteps,
      };
    }
    case CHANGE_INFO: {
      return {
        ...state,
        technologyName: payload.technologyName,
        firstName: payload.firstName,
        lastName: payload.lastName,
        companyName: payload.companyName,
      };
    }
    case UPDATE_LEVEL1_HRL_DATA: {
      return {
        ...state,
        level1HrlData: payload,
      };
    }
    case UPDATE_LEVEL1_HRL_SCORE: {
      return {
        ...state,
        level1HrlScore: payload,
      };
    }
    case UPDATE_LEVEL2_HRL_DATA: {
      return {
        ...state,
        level2HrlData: payload,
      };
    }
    case UPDATE_LEVEL2_HRL_SCORE: {
      return {
        ...state,
        level2HrlScore: payload,
      };
    }
    case UPDATE_LEVEL3_HRL_DATA: {
      return {
        ...state,
        level3HrlData: payload,
      };
    }
    case UPDATE_LEVEL3_HRL_SCORE: {
      return {
        ...state,
        level3HrlScore: payload,
      };
    }
    case UPDATE_LEVEL4_HRL_DATA: {
      return {
        ...state,
        level4HrlData: payload,
      };
    }
    case UPDATE_LEVEL4_HRL_SCORE: {
      return {
        ...state,
        level4HrlScore: payload,
      };
    }
    case UPDATE_LEVEL5_HRL_DATA: {
      return {
        ...state,
        level5HrlData: payload,
      };
    }
    case UPDATE_LEVEL5_HRL_SCORE: {
      return {
        ...state,
        level5HrlScore: payload,
      };
    }
    case UPDATE_LEVEL6_HRL_DATA: {
      return {
        ...state,
        level6HrlData: payload,
      };
    }
    case UPDATE_LEVEL6_HRL_SCORE: {
      return {
        ...state,
        level6HrlScore: payload,
      };
    }
    case UPDATE_LEVEL7_HRL_DATA: {
      return {
        ...state,
        level7HrlData: payload,
        dateCompleted: new Date(),
      };
    }
    case UPDATE_LEVEL7_HRL_SCORE: {
      return {
        ...state,
        level7HrlScore: payload,
      };
    }
    case UPDATE_STORE: {
      return {
        trlScore: payload.trlScore || 0,
        trlDefinition: payload.trlDefinition || '',
        mrlScore: payload.mrlScore || 0,
        mrlDefinition: payload.mrlDefinition || '',
        hrlScore: payload.hrlScore || 0,
        hrlDefinition: payload.hrlDefinition || '',
        dateCompleted: payload.dateCompleted || null,
        currentActive: payload.currentActive || PRODUCTION_FULL_OPERATIONAL_TESTING,
        trlQuestionsAnswered: payload.trlQuestionsAnswered || {},
        mrlQuestionsAnswered: payload.mrlQuestionsAnswered || {},
        hrlQuestionsAnswered: payload.hrlQuestionsAnswered || {},
        trlNextSteps: payload.trlNextSteps || [],
        mrlNextSteps: payload.mrlNextSteps || [],
        hrlNextSteps: payload.hrlNextSteps || [],
        firstName: payload.firstName || '',
        lastName: payload.lastName || '',
        technologyName: payload.technologyName || '',
        companyName: payload.companyName || '',
        environmentInputs: payload.environmentInputs || [],
        level1HrlData: payload.level1HrlData || [],
        level2HrlData: payload.level2HrlData || [],
        level3HrlData: payload.level3HrlData || [],
        level4HrlData: payload.level4HrlData || [],
        level5HrlData: payload.level5HrlData || [],
        level6HrlData: payload.level6HrlData || [],
        level7HrlData: payload.level7HrlData || [],
        level1HrlScore: payload.level1HrlScore || 0,
        level2HrlScore: payload.level2HrlScore || 0,
        level3HrlScore: payload.level3HrlScore || 0,
        level4HrlScore: payload.level4HrlScore || 0,
        level5HrlScore: payload.level5HrlScore || 0,
        level6HrlScore: payload.level6HrlScore || 0,
        level7HrlScore: payload.level7HrlScore || 0,
      };
    }
    default:
      return { ...state };
  }
};
