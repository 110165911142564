import { useState, useLayoutEffect } from 'react';
import { TRL_QUESTIONS, TEST_QUESTIONS } from '../data/questions';
import { EnvironmentInputProps } from '../store/actions/TrlMrlCalculator';
import { QuestionAnswer } from '../store/reducers/TrlMrlCalculator';
// Main navigation
export const QUESTIONS = 'questions';
export const WELCOME = 'welcome';
export const RESULTS = 'results';
export const RESULTS_PREVIEW = 'results preview';
export const HRL_TYPE = 'hrl assessment type';
export const RAPID_HRL = 'rapid hrl assessment';
export const INDEPTH_HRL = 'in-depth hrl assessment';

// Results Page navigation
export const READINESS_LEVELS = 'Readiness Results';
export const ANSWERS_OVERVIEW = 'Answers Overview';
export const SUGGESTED_NEXT_STEPS = 'Suggested Next Steps';

interface TitlesInfoProps {
  title: string;
  definition?: string;
}
interface TitlesProps {
  [key: string]: TitlesInfoProps;
}

// Page subtitles
export const TITLES: TitlesProps = {
  // [WELCOME]: {
  //   title: 'Welcome to TechGauge!',
  //   definition:
  //     'TechGauge is a tool to standardize the process of determining your technology’s readiness levels. TRL (Technology Readiness Level) and HRL (Human Readiness Level) are scales that the Air Force and other organizations use to gauge the maturity and human readiness of technologies. The values in the scale are intended to show how far along a technology is on the path from concept to a commercially available product.',
  // },
  [WELCOME]: {
    title: ' System Readiness',
    definition:
      'Tech gauge provides a suite of assessment tools that will help you assess the readiness of your new technology in terms of TRL (Technology Readiness Levels) , HRL (Human Readiness Levels), XAI (AI Explainability Levels) and MRL (Manufacturing Readiness Levels).',
  },
  [QUESTIONS]: {
    title: 'Assess Technology',
  },
  [RESULTS_PREVIEW]: {
    title: 'Assess Technology Readiness',
  },
  [HRL_TYPE]: {
    title: 'Assess Technology Readiness',
  },
  [INDEPTH_HRL]: {
    title: 'Assess Technology Readiness',
  },
  [RAPID_HRL]: {
    title: 'Assess Technology',
  },
};

// Window sizes
export const TABLE_WIDTH = 768;
export const DESKTOP_WIDTH = 1024;

// custom hook to watch for browser window resize
export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const getEnvironmentInputs = (trlQuestionsAnswered: QuestionAnswer): EnvironmentInputProps[] => {
  const environmentInputs: EnvironmentInputProps[] = [];
  TEST_QUESTIONS.forEach(key => {
    const envAnswer = trlQuestionsAnswered[key]?.yes;
    if (envAnswer) {
      environmentInputs.push({
        title: TRL_QUESTIONS[key]?.highlight,
        inputText: envAnswer.inputText,
      });
    }
  });

  return environmentInputs;
};
