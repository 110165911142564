import React, { ReactElement } from 'react';
import './Tabs.scss';

export interface TabProps {
  children?: any;
  index?: number;
  title: string;
  active?: boolean;
  visible?: boolean;
  showDivider?: boolean;
  onSelected?: (key: number) => void;
}

export const Tab = ({ index, showDivider, title, active, visible, onSelected }: TabProps): ReactElement => {
  const onTabSelected = (item: number): void => {
    if (onSelected) {
      onSelected(item);
    }
  };

  const tabClassName = active ? 'tab active' : 'tab';
  const tabTitleClassName = active ? 'tab_title selected' : 'tab_title';

  return (
    <>
      {visible ? (
        <div key={index} className={tabClassName}>
          <div
            className={tabTitleClassName}
            role="button"
            tabIndex={index || 0}
            onClick={(): void => onTabSelected(index || 0)}
            onKeyPress={(): void => onTabSelected(index || 0)}
          >
            {title}
          </div>
          {showDivider && <div className="tab_divider" />}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

Tab.defaultProps = {
  active: false,
  visible: true,
  showDivider: true,
};

export default Tab;
