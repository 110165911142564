/* eslint-disable prettier/prettier */
/* eslint-disable global-require */
import React, { ReactElement } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material/';
import { blueGrey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
// Icons
import LaptopIcon from '@mui/icons-material/Laptop';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
// Components
import Header from '../../components/header/Header';
import Restart from '../../components/restart/Restart';
// import Instructions from '../../components/instructions/Instructions';
import { SharedCalculatorProps } from '../../TrlMrlCalcInterface';
import { changePage } from '../../store/actions/Navigation';
import { changeQuestion } from '../../store/actions/TrlMrlCalculator';
import { Store } from '../../store/reducers';
// Helper
import { QUESTIONS, INDEPTH_HRL, RAPID_HRL, RESULTS } from '../../utils/helper';
import { HRL_9, PRODUCTION_FULL_OPERATIONAL_TESTING } from '../../data/questions';

import './Welcome.scss';

interface OptionsProps {
  option: string; 
  page: string;
  score: number;
  // eslint-disable-next-line react/require-default-props
  currentQuestion?: string;
    // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
}

const Option = ({option, page, score, currentQuestion = '', disabled = false}: OptionsProps): ReactElement => {
  const dispatch = useDispatch();

  const handleSelection = (): any => {
    dispatch(changePage(page));
    if (currentQuestion !== '') dispatch(changeQuestion(currentQuestion));
  };

  const getIcon = (): ReactElement => {
    switch (option) {
      case 'TRL':
        return <LaptopIcon sx={{ fontSize: 80 }} />;
      case 'Rapid HRL':
        return <GroupIcon sx={{ fontSize: 80 }} />;
      case 'In-depth HRL':
        return <GroupAddIcon sx={{ fontSize: 80 }} />;
      case 'XAI':
        return <DeveloperModeIcon sx={{ fontSize: 80 }} />;
      default:
        return <PrecisionManufacturingIcon sx={{ fontSize: 80 }} />;
    }
  };

  return (
    <Grid item container xs={12} md={2} justifyContent="center" sx={{ border: 1, borderColor: blueGrey[100], borderRadius: 5, p: '16px', m: 2}}>
      <IconButton color="primary" disabled={disabled}>
        {getIcon()}
      </IconButton>
      <Button
        fullWidth
        variant="contained"
        onClick={handleSelection}
        disabled={disabled}
      >
        {option}
      </Button>

      {disabled ? (<Typography sx={{ mt: 4, color: 'grey'}}>COMING SOON</Typography>) : (
        <Grid container direction="row" justifyContent="center" sx={{mt: 4}}>
          <Typography>Current score: </Typography>
          <Typography sx={{ml: 2}}>{score}</Typography>
        </Grid>
      )}
      {score > 0 && <Button variant="text" onClick={(): void => dispatch(changePage(RESULTS))}>See results</Button>}
    </Grid>
  )
}

const Welcome = ({ homepageText, onRestart }: SharedCalculatorProps): ReactElement => {
  const {
    trlScore,
    hrlScore,
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore,
  } = useSelector((state: Store) => state.TrlMrlCalculator);

  const indepthHrlScore = Math.min(
    level1HrlScore,
    level2HrlScore,
    level3HrlScore,
    level4HrlScore,
    level5HrlScore,
    level6HrlScore,
    level7HrlScore
  );

  return (
    <div className="Welcome" data-testid="welcome">
      <Header
        title="Are you sure you want to restart the assessment?"
        content="If you restart the assessment, all of the progress you have made will be lost."
        homepageText={homepageText}
      />
      <Box>
        <Grid container justifyContent='flex-end' sx={{pr: 2}}>
        {(trlScore > 0 || hrlScore > 0 || indepthHrlScore > 0) && (
          <Restart 
            title="Are you sure you want to clear all data?"
            content="If you clear all data, all of the answers and scores saved in the system will be lost." 
            confirmText="Clear All"
            onRestart={onRestart} 
            buttonText='Clear All' 
            isClearAll
          />
        )}
        </Grid>
        <Grid container justifyContent="center" sx={{mt: 5}}>
          <Option option="TRL" page={QUESTIONS} currentQuestion={PRODUCTION_FULL_OPERATIONAL_TESTING} score={trlScore}/>
          <Option option="Rapid HRL" page={RAPID_HRL} currentQuestion={HRL_9} score={hrlScore}/>
          <Option option="In-depth HRL" page={INDEPTH_HRL} score={indepthHrlScore} />
          <Option option="XAI" page={QUESTIONS} score={0} disabled/>
          <Option option="MRL" page={QUESTIONS} score={0} disabled/>
        </Grid>
      </Box>
    </div>
  );
};

export default Welcome;
