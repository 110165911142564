import React, { ReactElement } from 'react';
import './Legend.scss';

const Legend = (): ReactElement => {
  return (
    <section className="Legend">
      <section className="Legend__group">
        <span className="Legend__group__dot resColor" />
        <p>R&D</p>
      </section>
      <section className="Legend__group">
        <span className="Legend__group__dot protoColor" />
        <p>Prototype</p>
      </section>
      <section className="Legend__group">
        <span className="Legend__group__dot prodColor" />
        <p>Production</p>
      </section>
    </section>
  );
};

export default Legend;
