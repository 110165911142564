import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { INDEPTH_HRL, RAPID_HRL } from '../../utils/helper';
import { changePage } from '../../store/actions/Navigation';
import CustomRadioField from '../../components/shared/CustomRadioField';
import Header from '../../components/header/Header';

const HrlType = ({ displayRestartBttn, onRestart }): ReactElement => {
  const dispatch = useDispatch();
  const [selectedAssessment, setAssessment] = useState('rapid');

  const goToChosenAssessment = (): void => {
    if (selectedAssessment === 'rapid') {
      dispatch(changePage(RAPID_HRL));
    } else if (selectedAssessment === 'in-depth') {
      dispatch(changePage(INDEPTH_HRL));
    }
  };

  return (
    <div>
      <Header
        title="Are you sure you want to start a new assessment?"
        content="If you restart the assessment, all of the progress you have made will be lost."
        displayRestartBttn={displayRestartBttn}
        onRestart={onRestart}
      />
      <Box
        sx={{ display: 'grid', gridTemplateRows: '5em 200px auto', paddingX: '3em', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography variant="h3">HRL Assessment Type</Typography>
          <Typography>
            Select the Assessment type you would like to take. For this version of tech gauge please fill out the rapid
            assessment first and then proceed to the in depth assessment.
          </Typography>
        </Box>
        <Card
          sx={{
            width: '475px',
            height: '175px',
            gridColumn: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardContent>
            <Typography variant="h3">Rapid Assessment</Typography>
            <Typography>A couple of minutes for a ball park result.</Typography>
          </CardContent>
          <CardActions>
            <CustomRadioField
              name="I would like to take the Rapid Assessment"
              value="rapid"
              onCheck={(): void => setAssessment('rapid')}
              checked={selectedAssessment === 'rapid'}
            />
          </CardActions>
        </Card>
        <Card
          sx={{
            width: '475px',
            height: '175px',
            gridColumn: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardContent>
            <Typography variant="h3">In-depth Assessment</Typography>
            <Typography>
              Gathers more information to understand methods and can provide a more accurate result.
            </Typography>
          </CardContent>
          <CardActions>
            <CustomRadioField
              name="I would like to take the In-depth Assessment"
              value="in-depth"
              onCheck={(): void => setAssessment('in-depth')}
              checked={selectedAssessment === 'in-depth'}
            />
          </CardActions>
        </Card>
        <Button
          variant="contained"
          disabled={!selectedAssessment}
          sx={{ gridColumn: 2, justifySelf: 'flex-end' }}
          onClick={(): void => goToChosenAssessment()}
        >
          Continue
        </Button>
      </Box>
    </div>
  );
};

export default HrlType;
