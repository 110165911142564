import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import CustomModal from './CustomModal';

export type Props = {
  isOpen: boolean;
  title: string;
  content: string | JSX.Element;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export default ({ isOpen, title, content, cancelText, confirmText, onCancel, onConfirm }: Props): ReactElement => {
  return (
    <CustomModal isOpen={isOpen} onClose={onCancel}>
      <div className="ModalTemplate">
        <div className="ModalTemplate__title">
          <h1>{title}</h1>
        </div>
        <div className="ModalTemplate__content">
          <strong>Note:</strong> {content}
        </div>
        <div className="ModalTemplate__close">
          <section className="ModalTemplate__close-buttons">
            <Button onClick={onCancel} variant="outlined" color="secondary" sx={{ marginRight: '1rem' }}>
              {cancelText || 'Cancel'}
            </Button>
            <Button onClick={onConfirm} variant="contained">
              {confirmText || 'Go back'}
            </Button>
          </section>
        </div>
      </div>
    </CustomModal>
  );
};
