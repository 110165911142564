import React, { ReactElement } from 'react';
import { useWindowSize, TABLE_WIDTH } from '../../utils/helper';
import './ProgressBar.scss';

interface ProgressBarProps {
  percent: number;
}

const ProgressBar = ({ percent }: ProgressBarProps): ReactElement => {
  const [width] = useWindowSize();
  return (
    <div className="Progress-bar-container">
      <svg className="ProgressBar" viewBox={`0 0 100 ${width < TABLE_WIDTH ? 4 : 1}`}>
        <g className="Line">
          <line className="Background" x1="0" y1="0" x2="100%" y2="0" />
          <line
            className="Completed"
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            style={{
              strokeDasharray: `${Math.abs(percent)} 100`,
              strokeLinecap: 'butt',
            }}
          />
        </g>
      </svg>
    </div>
  );
};

export default ProgressBar;
