import React, { ReactElement, useState } from 'react';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { TRL_QUESTIONS, HRL_QUESTIONS } from '../../data/questions';
import { restartAll, restartAssessment } from '../../store/actions/TrlMrlCalculator';
import { changePage } from '../../store/actions/Navigation';
import { WELCOME } from '../../utils/helper';
import { SharedCalculatorProps } from '../../TrlMrlCalcInterface';
import { Store } from '../../store/reducers';
// Components
import ConfirmModal from '../modal/ConfirmModal';
// Style
import './Restart.scss';

export interface RestartProps extends SharedCalculatorProps {
  title: string;
  content: string;
  // eslint-disable-next-line react/require-default-props
  confirmText?: string;
  // eslint-disable-next-line react/require-default-props
  buttonText?: string;
  // eslint-disable-next-line react/require-default-props
  isClearAll?: boolean;
}

const Restart = ({
  title,
  content,
  onRestart,
  confirmText = 'Go back',
  buttonText = 'Go to Dashboard',
  isClearAll = false,
}: RestartProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentActive } = useSelector((state: Store) => state.TrlMrlCalculator);

  const restartStore = (): object => dispatch(restartAll());

  const restartCurrent = (assessmentType: string): object => dispatch(restartAssessment(assessmentType));

  const openModal = (): void => setOpen(true);

  const closeModal = (): void => setOpen(false);

  const goToWelcomePage = (): void => dispatch(changePage(WELCOME));

  const identifyAssessment = (): string => {
    if (TRL_QUESTIONS[currentActive] !== undefined) {
      return 'TRL';
    }

    if (HRL_QUESTIONS[currentActive] !== undefined) {
      return 'Rapid HRL';
    }

    return 'In-depth HRL';
  };

  const restartData = (): void => {
    if (isClearAll) {
      restartStore();
    } else {
      const assessmentType = identifyAssessment();
      restartCurrent(assessmentType);
    }

    closeModal();
    goToWelcomePage();
  };

  const handleClick = () => {
    if (onRestart) onRestart();
    else openModal();
  };

  return (
    <section className="RestartButton" data-testid="restart">
      <Button variant="outlined" color="secondary" onClick={handleClick}>
        {buttonText}
      </Button>
      <ConfirmModal
        isOpen={open}
        onCancel={closeModal}
        onConfirm={restartData}
        title={title}
        content={content}
        confirmText={confirmText}
      />
    </section>
  );
};

export default Restart;
