import React, { ReactElement } from 'react';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomRadioField from '../../components/shared/CustomRadioField';

const InDepthCardInputs = ({ control, input }): ReactElement => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexBasis: '33%',
        height: 'fit-content',
      }}
    >
      <CardContent>
        <Typography variant="h5" sx={{ marginBottom: '1em' }}>
          {input.heading}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          {input.image && <Box component="img" src={input.image} sx={{ maxWidth: '50%', maxHeight: '150px' }} />}
          {input.description ? (
            <ul>
              {input.description.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          ) : (
            <p>N/A</p>
          )}
        </Box>
      </CardContent>
      {input.description && (
        <CardActions>
          <Controller
            control={control}
            name={`${input.level}`}
            rules={{ required: true }}
            defaultValue="completed"
            render={({ field: { onChange, value } }): ReactElement => (
              <Box sx={{ flexBasis: '100%' }}>
                <CustomRadioField
                  name={`${input.level}: Completed`}
                  label="Completed"
                  value="completed"
                  onCheck={onChange}
                  checked={value === 'completed'}
                />
                <CustomRadioField
                  name={`${input.level}: Partially Completed`}
                  label="Partially Completed"
                  value="partially completed"
                  onCheck={onChange}
                  checked={value === 'partially completed'}
                />
                <CustomRadioField
                  name={`${input.level}: Not Started`}
                  label="Not Started"
                  value="not started"
                  onCheck={onChange}
                  checked={value === 'not started'}
                />
              </Box>
            )}
          />
        </CardActions>
      )}
    </Card>
  );
};

export default InDepthCardInputs;
